import { eqOne } from "../../fxns/operatorFxns";
import acronyms from "../../utils/acronyms";
const a61to90_Mng_SLo = (fullStateObj, dispatcher) => {
  const {
    core: {
      coreBase: {
        age,
        feverInflam,
        congenRisk,
        focalDx,
        ill,
        imm,
        penias,
        hypotherm,
        hsvRisk,
        view,
      },
    },
    derFever: {
      derFeverBase: {
        fluidWorkup: { Urine: uWU, Blood: bWU, CSF: cWU },
        fluidInflamm: { Urine: uI, Blood: bI, CSF: cI, loInflam },
        mngRecs: { Therapy, Dispo, Course },
      },
    },
  } = fullStateObj;

  const {
    Y,
    YLP,
    MLP,
    NNLP,
    NN,
    PLP,
    DEP,
    illa,
    bro,
    cov,
    mayNoAbx,
    abx,
    abxPleo,
    abxAcyclo,
    hsvStudies,
    abxHsvRiskCongen,
    mayAbx,
    nnAbx,
    min24,
    adm,
    dLP,
    C,
    D,
    trau,
    unsuc,
    hospOrHome,
    abxParOrOral,
    oralAbx,
    obsHome,
    obsHomeUnlessResp,
    fu12to24,
    fu24to36,
    discWiPeds,
  } = acronyms;

  const dispatchinator = (rx, disp, cou) => {
    return dispatcher("mngRecs", {
      Therapy: rx,
      Dispo: disp,
      Course: cou,
    });
  };

  const logicizer = () => {
    if (
      ill === illa ||
      cI === true ||
      (uWU === Y && bWU === Y && cWU === YLP)
    ) {
      dispatchinator(abx, adm, min24);
      return;
    }

    if (bI === true) {
      dispatchinator(abx, discWiPeds, discWiPeds);
      return;
    }

    // ! zz HOSP Abx :
    if (congenRisk.length > 0) {
      dispatchinator(discWiPeds, discWiPeds, discWiPeds);
      return;
    }

    // ! negOrDeferredUrine OR Imm, Bro, Cov - NO Abx :
    if (
      eqOne(uI, [false, D]) ||
      imm ||
      focalDx.includes(bro) ||
      focalDx.includes("COVID positive")
    ) {
      dispatchinator(NN, obsHome, fu24to36);
      return;
    }

    if (uI === true) {
      dispatchinator(oralAbx, obsHome, fu12to24);
      return;
    }
  };
  logicizer();
};

export default a61to90_Mng_SLo;
