import { CHANGE_DERIVED_FEVER_STATE } from "./types";
import { RESET_DERIVED_FEVER_STATE } from "./types";
import { CHANGE_CORE_STATE } from "./types";
import { CHANGE_INFLAM_STATE } from "./types";
import { CHANGE_WORKUP_STATE } from "./types";

export const changeDerFeverState = (f, v, nextView) => async (dispatch) => {
  try {
    dispatch({
      type: CHANGE_DERIVED_FEVER_STATE,
      payload: { field: f, value: v },
    });
    nextView &&
      dispatch({
        type: CHANGE_CORE_STATE,
        payload: { field: "view", value: nextView },
      });
  } catch (err) {
    console.log("Something went wrong with changeDerState -> changeDerStateAC");
  }
};

export const changeInflamState = (f, v, nextView) => async (dispatch) => {
  try {
    dispatch({
      type: CHANGE_INFLAM_STATE,
      payload: { fluid: f, value: v },
    });
    nextView &&
      dispatch({
        type: CHANGE_CORE_STATE,
        payload: { field: "view", value: nextView },
      });
  } catch (err) {
    console.log(
      "Something went wrong with changeInflamState -> changeInflamStateAC"
    );
  }
};

export const changeWorkupState = (f, v, nextView) => async (dispatch) => {
  try {
    dispatch({
      type: CHANGE_WORKUP_STATE,
      payload: { fluid: f, value: v },
    });
    nextView &&
      dispatch({
        type: CHANGE_CORE_STATE,
        payload: { field: "view", value: nextView },
      });
  } catch (err) {
    console.log(
      "Something went wrong with changeWorkUpState -> changeWorkUpStateAC"
    );
  }
};

export const resetDerFeverStateState = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_DERIVED_FEVER_STATE,
    });
  } catch (err) {
    console.log(
      "Something went wrong with resetDerFeverState -> resetDerFeverStateAC"
    );
  }
};
