import React from "react";
import { Message } from "semantic-ui-react";
import acronyms from "../../../utils/acronyms";
import { useSelector } from "react-redux";
import { eqOne, allTrue } from "../../../fxns/operatorFxns";

const RecsHeaderView = () => {
  const {
    age,
    feverInflam,
    congenRisk,
    focalDx,
    ill,
    imm,
    penias,
    hypotherm,
    hsvRisk,
    view,
  } = useSelector((state) => state.core.coreBase);
  const {
    Urine: uI,
    Blood: bI,
    CSF: cI,
  } = useSelector((state) => state.derFever.derFeverBase.fluidInflamm);
  const {
    derFever: {
      derFeverBase: { fluidWorkup, fluidInflamm },
    },
  } = useSelector((state) => state);

  const {
    Urine: UrWu,
    Blood: BlWu,
    CSF: CsfWu,
  } = useSelector((state) => state.derFever.derFeverBase.fluidWorkup);
  const checkFullWu = () => {
    return UrWu &&
      UrWu === "YES" &&
      BlWu === "YES" &&
      CsfWu === "YES perform LP" &&
      allTrue(null, [uI, bI, cI])
      ? true
      : false;
  };

  const {
    Y,
    YLP,
    MLP,
    NNLP,
    NN,
    PLP,
    DEP,
    illa,
    bro,
    mayNoAbx,
    M,
    abx,
    mayAbx,
    nnAbx,
    min24,
    adm,
    dLP,
    D,
    trau,
    unsuc,
    cov,
    matLesions,
    vesicles,
  } = acronyms;

  const hsvRiskList = () => {
    const hsvRiskObj = {
      "Penias": penias,
      "Hypothermia": hypotherm,
      "Maternal HSV lesions at delivery": congenRisk.includes(matLesions),
      "Vesicles": focalDx.includes(vesicles),
      "Maternal Chorio or Fever within 48 hours of birth": congenRisk.includes(
        "Maternal Chorio or Fever within 48 hours of birth"
      ),
    };
    let riskArr = [];
    for (let x in hsvRiskObj) {
      hsvRiskObj[x] === true && riskArr.push(x);
    }
    riskArr = riskArr.join(", ");
    return riskArr;
  };

  const Msg = (props) => {
    const { msgTxt, color, icon = null } = props;
    return (
      <Message
        icon={icon}
        header={
          msgTxt === "init"
            ? `${msgObj["Msg"][msgTxt]}`
            : `** ${msgObj["Msg"][msgTxt]}`
        }
        // content={msgObj[view].content}
        size="mini"
        floating
        color={color}
        style={{ textAlign: "left" }}
      />
    );
  };
  const msgObj = {
    Msg: {
      init: "Initial recommended workup",
      awa: " >> Awaiting full workup:",
      ill: "Ill appearing infants in this age range require a timely full workup AND antibiotics AND early consult to pediatrics",
      abx: "This infant will require Abx after workup: due to HIGH risk labs",
      bronchio:
        "In the setting of Bronchiolitis in this age group, the following recommendations for a less-aggressive workup are operative",
      cathUrine: "Confirm Urine was obtained by Bladder Cath !",
      sz: "In this age group, the presence of seizures in the setting of fever demands Attempt LP and consideration for HSV",
      hsvRisk: `This infant's HSV risks (${hsvRiskList()}) call for LP attempt, send HSV studies and Acyclovir`,
      considerHSV:
        "Given this infant's age and risk criteria, consider evaluating for possible HSV risk",
      csfPleoHsvRisk:
        "If CSF pleocytosis, esp. if negative GramStain, assess for possible HSV and Consider Acyclovir",
      hsvStudies:
        "HSV studies: 1. CSF for HSV PCR, 2. HSV PCR of serum 3. HSV surface swabs: conjunctiva, NP, skin, vesicles, rectal",
      covid:
        "Current data support management of COVID positive infants with fever in same manner as Bronchiolitis:",
      /* consPeds: "Consult with PEDS given COVID positive", */
      imm: "Infants this age, without additional risks, who have received Immunizations in the last 48 hrs NEED NO STUDIES and may go home without further testing",
      immHiRiskPedsCon:
        "Infants like this one, who have received Immunizations in the last 48 hrs, but also have other High Risks, warrant Consult with Peds to determine appropriate workup and management recs",
      hyperT: "Temp >38.5 in this age group demands full fluid workup",
      fullWuAndHSV:
        "Based upon risks, this infant requires FULL WORKUP of all body fluids, initiation of empiric antibiotics, and consideration for HSV.",
      focalBac:
        "Workup and Management per accepted standards for the Focal Infection",
    },
  };
  return !checkFullWu() ? (
    <>
      {/* Init vs Waiting Blu Hdr */}
      {(uI === null &&
        bI === null &&
        cI === null &&
        ill !== "Ill-appearing" && (
          <Msg msgTxt={"init"} color="blue" icon="sitemap" />
        )) || <Msg msgTxt={"awa"} color="green" icon="sitemap" />}

      {/* Ill, Bronchio, HyperT */}
      {ill === "Ill-appearing" && <Msg msgTxt={"ill"} color="red" />}
      {focalDx.includes("Bronchiolitis") &&
        !focalDx.includes(cov) &&
        fluidWorkup["CSF"] !== YLP && (
          <Msg msgTxt={"bronchio"} color="yellow" />
        )}

      {imm &&
        focalDx.includes("Bronchiolitis") &&
        !eqOne(age, ["7-21 days"]) &&
        congenRisk.length === 0 && <Msg msgTxt={"imm"} color="green" />}

      {imm &&
        UrWu === "Need not" &&
        BlWu === "Need not" &&
        CsfWu === "NEED NOT perform LP" && <Msg msgTxt={"imm"} color="green" />}

      {feverInflam && !focalDx.includes("Bronchiolitis") && (
        <Msg msgTxt={"hyperT"} color="orange" />
      )}

      {/* COVID */}
      {focalDx.includes("COVID positive") &&
        eqOne(age, ["7-21 days", "22-28 days"]) && (
          <Msg msgTxt={"covid"} color="orange" />
        )}

      {/* HSV */}
      {(hsvRisk || hypotherm) &&
        congenRisk.length > 0 &&
        eqOne(age, ["7-21 days", "22-28 days", "29-60 days"]) && (
          <>
            <Msg msgTxt={"hsvRisk"} color="red" />
            <Msg msgTxt={"hsvStudies"} color="purple" />
          </>
        )}

      {!hsvRisk &&
        !hypotherm &&
        congenRisk.length > 0 &&
        !(focalDx.some((x) => [bro, cov].includes(x)) || imm) &&
        eqOne(age, ["7-21 days", "22-28 days", "29-60 days"]) && (
          <Msg msgTxt={"considerHSV"} color="orange" />
        )}

      {(hsvRisk || hypotherm) &&
        congenRisk.length === 0 &&
        eqOne(age, ["7-21 days", "22-28 days", "29-60 days"]) && (
          <>
            <Msg msgTxt={"considerHSV"} color="orange" />
            <Msg msgTxt={"hsvStudies"} color="purple" />
          </>
        )}

      {/* Single Triggers */}
      {feverInflam && !focalDx.includes("Bronchiolitis") && (
        <Msg msgTxt={"hyperT"} color="orange" />
      )}
      {fluidWorkup["Urine"] ===
        "Workup and Rx per accepted standards for the Focal Infection" && (
        <Msg msgTxt={"focalBac"} color="orange" />
      )}
      {focalDx.includes("Seizures") && <Msg msgTxt={"sz"} color="red" />}
      {cI && <Msg msgTxt={"csfPleoHsvRisk"} color="orange" />}
      {/* Change with DOS: */}
      {UrWu &&
        imm &&
        UrWu === "Discuss with Peds" &&
        BlWu === "Discuss with Peds" &&
        CsfWu === "Discuss with Peds" &&
        allTrue(null, [uI, bI, cI]) && (
          <Msg msgTxt={"immHiRiskPedsCon"} color="orange" />
        )}
    </>
  ) : (
    <>
      {/* <Msg msgTxt={"init"} color="blue" icon="sitemap" /> */}
      {eqOne(age, ["7-21 days", "22-28 days", "29-60 days"]) && (
        <Msg msgTxt={"fullWuAndHSV"} color="red" />
      )}
    </>
  );
};

export default RecsHeaderView;
