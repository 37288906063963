import React from "react";
import history from "../../history";
import { Button } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import {
  changeState,
  changeViewOnly,
  resetCoreState,
} from "../../1actions/core_actions";
import { setHypo } from "../../1actions/core_actions";
import { changeInflamState } from "../../1actions/der_fever_actions";
import { eqOne } from "../../fxns/operatorFxns";

const InputsBtn = ({
  btnProps: {
    field,
    val,
    content,
    nextView,
    color,
    icon,
    labelPosition,
    histoPush = null,
  },
  addToArr = null,
  addToHsvVuState = null,
}) => {
  const dispatch = useDispatch();
  const handleClick = (
    field,
    val,
    nextView,
    histoPush = null,
    addToArr = null,
    addToHsvVuState = null,
    content = null
  ) => {
    if (val === "hypo") {
      dispatch(setHypo("hypotherm", true));
    } else {
      dispatch(changeState(field, val, nextView));
      addToArr && addToArr();
      addToHsvVuState && addToHsvVuState();
      content === "Positive Gram Stain" &&
        dispatch(changeInflamState("CSF", "posGmStain"));
      histoPush &&
        history.push({
          pathname: histoPush,
          // state: { v },
        });
    }
  };
  const handleArrClick = (field, val, nextView, histoPush = null) => {
    // console.log(field, val);
    val === null
      ? dispatch(changeViewOnly(nextView))
      : dispatch(changeState(field, val));
    hsvLabel(val);
  };

  const hsvLabel = (val) => {
    eqOne(val, [
      "Maternal history genital HSV lesions",
      "Seizures",
      "Skin or Oral vesicles",
    ]) && dispatch(changeState("hsvRisk", true));
  };

  const handleDecideClick = (val, nextView) => {
    if (val === "deferLP") {
      dispatch(changeInflamState("CSF", "deferredLP"));
    }
    if (val === "planLP") {
      dispatch(changeInflamState("CSF", "planningLP"));
    }
  };

  if (field === "exclusions") {
    return (
      <Button
        onClick={() => dispatch(resetCoreState())}
        content={content}
        icon={icon}
        labelPosition={labelPosition}
        style={{ ...styles.btnBaseStyle, backgroundColor: color }}
      />
    );
  }

  if (field === "focalDx" || field === "congenRisk") {
    return (
      <Button
        onClick={() => handleArrClick(field, val, nextView)}
        content={content}
        icon={icon}
        labelPosition={labelPosition}
        style={{ ...styles.congenStyle, backgroundColor: color }}
      />
    );
  }

  if (field === "LPDecide") {
    return (
      <Button
        onClick={() => handleDecideClick(val)}
        content={content}
        style={{
          ...styles.btnBaseStyle,
          backgroundColor: color,
          fontSize: "0.6em",
          marginLeft: "2em",
          opacity: "0.8",
        }}
      />
    );
  }

  if (field === "ill") {
    return (
      <Button
        onClick={() => handleClick(field, val, nextView, null, addToArr)}
        content={content}
        icon={icon}
        labelPosition={labelPosition}
        style={{
          ...styles.btnBaseStyle,
          backgroundColor: color,
          fontSize: "1.0em",
          lineHeight: "1.2em",
          marginBottom: "0em",
          marginTop: "0em",
        }}
      />
    );
  }

  return (
    <Button
      onClick={() =>
        handleClick(
          field,
          val,
          nextView,
          histoPush,
          addToArr,
          addToHsvVuState,
          content
        )
      }
      content={content}
      icon={icon}
      labelPosition={labelPosition}
      style={{ ...styles.btnBaseStyle, backgroundColor: color }}
    />
  );
};

const styles = {
  illStyle: {
    margin: "0em 0em",
    color: "white",
  },
  congenStyle: {
    margin: "0em 0em",
    color: "white",
  },
  btnBaseStyle: {
    margin: "0.4em 1em",
    color: "white",
    lineHeight: "1.2em",
  },
};

export default InputsBtn;
