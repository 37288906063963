import React from "react";
// import history from "../../history";
import { List } from "semantic-ui-react";
import InputsBtn from "../elements/InputsBtn";
import { useSelector } from "react-redux";
import { eqOne } from "../../fxns/operatorFxns";

const FocalView = () => {
  const { age, congenRisk, focalDx } = useSelector(
    (state) => state.core.coreBase
  );
  const focalProcessOpt = [
    "Focal Bacterial infection",
    "Skin or Oral vesicles",
    "Seizures",
    "Bronchiolitis",
    "COVID positive",
    "Hypothermia: episodes of Temp <36.5C",
  ];

  const feverInflamViewDecider = () => {
    return eqOne(age, ["7-21 days", "22-28 days"]) &&
      congenRisk.length === 0 &&
      !(
        age === "22-28 days" &&
        (focalDx.includes("Bronchiolitis") ||
          focalDx.includes("COVID positive"))
      )
      ? "FeverInflam"
      : "RecsStart";
  };

  const excluChecker = () => {
    if (
      age === "7-21 days" &&
      (focalDx.includes("Bronchiolitis") ||
        focalDx.includes("COVID positive")) &&
      congenRisk.length > 0
    ) {
      return "Exclusions";
    } else {
      return feverInflamViewDecider();
    }
  };

  return (
    <div>
      <List>
        {focalProcessOpt.map(
          (x, i) =>
            !focalDx.includes(x) && (
              <List.Item
                key={i}
                style={{ margin: "0.4em 0 0 1em", fontSize: "1.1em" }}>
                <InputsBtn
                  btnProps={{
                    field: "focalDx",
                    val: x,
                    content: x,
                    // nextView: ,
                    icon: "white zoom in left large",
                    labelPosition: "left",
                    color: "orange",
                    // histoPush: "/exclu",
                  }}
                />
              </List.Item>
            )
        )}
      </List>
      <InputsBtn
        btnProps={{
          field: "focalDx",
          val: null,
          content: "None, or All conditions input",
          nextView: excluChecker(),
          color: "green",
          icon: "arrow right large",
          labelPosition: "right",
          // histoPush: "/exclu",
        }}
      />
    </div>
  );
};

export default FocalView;
