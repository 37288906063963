import React, { useState, useEffect } from "react";
// import history from "../../history";
import { List, Message, Button, Label } from "semantic-ui-react";
import InputsBtn from "../elements/InputsBtn";
import { useSelector } from "react-redux";

const OldView = () => {
  const { ill } = useSelector((state) => state.core.coreBase);
  const [recMsg, setRecMsg] = useState(
    ill === "ill-appearing"
      ? "The recommended workup for this infant is to search for IBI: Obtain CBC, CRP and Blood Culture, and if Altered MSE, LP as well"
      : false
  );
  const [vueArr, setVueArr] = useState(["ibi", "iz", "resp", "uti", "none"]);
  const [vue, setVue] = useState(vueArr[0]);
  const Opts = {
    ibi: [
      "The infant is 'ill appearing'",
      "The fever is 'High': minimum 39.0 (102.2) or greater, AND >5days, AND NO source is evident",
      "There is a focal bacterial infection (Abscess, Cellulitis)",
    ],
    iz: [
      "There is an undifferentiated fever, without source evident, now within 48 hours of recent Immunization",
    ],
    resp: [
      "Resp symptoms and now Fever >5days",
      "Infant is on COVID workflow",
      "Focal lung exam and without presentation consistent with Bronchiolitis",
    ],
    uti: [
      "There have been now >3 days of fever, without known source",
      "The infant has known UTI risk factors: Prior UTI, Hydronephrosis, Age <6mos male who is uncircumcised",
    ],
    none: ["No infant risks warranting workup"],
  };

  const Msgs = {
    ibi: "The recommended workup for this infant is to search for IBI: Obtain CBC, CRP and Blood Culture, and if Altered MSE, LP as well",
    iz: "Undifferentiated fever after routine immunization within 48 hours does not routinely require further workup",
    resp: "This infant meets criteria to obtain a CXR only",
    uti: "Cath UA and Urine Culture is recommended in this patient",
    none: "This infant does not require any workup for fever, unless 'Ill appearing'",
  };

  useEffect(() => {
    setVue(vueArr[0]);
  }, [vueArr]);

  useEffect(() => {
    vue === "none" && setRecMsg("No infant risks warranting workup");
  }, [vue]);

  useEffect(() => {
    ill === "Ill-appearing" &&
      setRecMsg(
        "The recommended workup for this infant is to search for IBI: Obtain CBC, CRP and Blood Culture, and if Altered MSE, LP as well"
      );
  }, [ill]);

  return (
    <div>
      {recMsg || vue === "none" ? (
        <>
          <List>
            <List.Item style={{ margin: "0.4em 0 0 1em", fontSize: "1.1em" }}>
              <Button
                // onClick={() => {}}
                content={recMsg}
                icon="white check left large"
                labelPosition="left"
                color="teal"
                style={{ ...styles.btnStyle }}
              />
            </List.Item>
          </List>
          <Message
            header={Msgs[vue]}
            size="mini"
            floating
            color="teal"
            style={{ textAlign: "left", marginTop: "1em" }}
          />
        </>
      ) : (
        <>
          <Message
            header="The following conditions would demand specifc management.  Please indicate whether any are true"
            size="mini"
            floating
            color="blue"
            style={{ textAlign: "left", marginTop: "1em" }}
          />
          <List>
            {Opts[vue].map((x, i) => (
              <List.Item
                key={i}
                style={{ margin: "0.4em 0 0 1em", fontSize: "1.1em" }}>
                <Button
                  onClick={() => setRecMsg(x)}
                  content={x}
                  icon="white question left large"
                  labelPosition="left"
                  color="teal"
                  style={{ ...styles.btnStyle }}
                />
              </List.Item>
            ))}
          </List>
          <Button
            onClick={() => setVueArr(vueArr.slice(1))}
            content="None of the above are true"
            icon="arrow right large"
            labelPosition="right"
            color="green"
            style={{ ...styles.btnStyle }}
          />
        </>
      )}
    </div>
  );
};

const styles = {
  btnStyle: {
    margin: "0em 0em",
    lineHeight: "1.4em",
    color: "white",
    textAlign: "left",
  },
};

export default OldView;
