import React from "react";
import { useSelector } from "react-redux";
import InputsBtn from "../elements/InputsBtn";

const ImmView = () => {
  const {
    core: {
      coreBase: { age, congenRisk, focalDx, ill, view },
    },
  } = useSelector((state) => state);

  return (
    <div>
      <InputsBtn
        btnProps={{
          field: "imm",
          val: true,
          content: "Yes",
          nextView: "Congen",
          color: "green",
        }}
      />
      <InputsBtn
        btnProps={{
          field: "imm",
          val: false,
          content: "No",
          nextView: "Congen",
          color: "red",
        }}
      />
    </div>
  );
};

export default ImmView;
