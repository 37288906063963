import React, { useEffect, useState } from "react";
import history from "../../history";
import {
  Label,
  Icon,
  Accordion,
  Modal,
  Image,
  Button,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { resetCoreState } from "../../1actions/core_actions.js";
import { changeInflamState } from "../../1actions/der_fever_actions";
import { eqOne } from "../../fxns/operatorFxns";
import acronyms from "../../utils/acronyms";
import well_7to21 from "../../assets/Graphics/well_7to21.png";

const LabelView = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };
  const dispatch = useDispatch();
  const {
    age,
    feverInflam,
    congenRisk,
    focalDx,
    ill,
    imm,
    penias,
    hypotherm,
    hsvRisk,
    view,
  } = useSelector((state) => state.core.coreBase);
  const { Urine, Blood, CSF, loInflam } = useSelector(
    (state) => state.derFever.derFeverBase.fluidInflamm
  );
  const {
    Y,
    YLP,
    MLP,
    NNLP,
    NN,
    PLP,
    DEP,
    illa,
    bro,
    cov,
    vesicles,
    matLesions,
    M,
    mayNoAbx,
    abx,
    abxPleo,
    abxHsvRiskCongen,
    abxAcyclo,
    hsvStudies,
    mayAbx,
    nnAbx,
    min24,
    adm,
    dLP,
    D,
    trau,
    unsuc,
  } = acronyms;

  useEffect(() => {
    if (Urine === false && Blood === false && CSF === false) {
      dispatch(changeInflamState("loInflam", true));
    }
  });

  const handleReset = () => {
    dispatch(resetCoreState());
    history.push({
      pathname: "/",
      // state: { v },
    });
  };

  const csfVal = () => {
    const val =
      CSF === true
        ? "* HI Inflam CSF screen"
        : CSF === D
        ? "Deferred LP"
        : CSF === trau
        ? "Traumatic LP"
        : CSF === unsuc
        ? "Unsuccesful LP"
        : null;
    return val;
  };

  const labelArrOrig = [
    { name: "age", val: age, color: "blue" },
    { name: "* Fever risk for age: >38.5C", val: feverInflam, color: "red" },
    { name: "* Hypothermia", val: hypotherm, color: "orange" },
    // { name: "congenRisk", val: congenRisk, color: "orange" },
    // { name: "focalDx", val: focalDx, color: "maroon" },
    {
      name: "ill",
      val: ill,
      color: `${ill === "Ill-appearing" ? "crimson" : "green"}`,
    },
    { name: "Recent Immunization", val: imm, color: "yellowgreen" },
    { name: "Leuko or Thrombo -penia", val: penias, color: "maroon" },
    {
      name: "CSF",
      val: csfVal(),
      color: `${
        CSF === true
          ? "red"
          : [trau, unsuc].some((x) => x === CSF)
          ? "orange"
          : CSF === D
          ? "purple"
          : "pink"
      }`,
    },
    { name: "* HI Inflam Urine screen", val: Urine, color: "red" },
    { name: "* HI Inflam Blood screen", val: Blood, color: "red" },
    { name: "* HSV risk", val: hsvRisk, color: "red" },
    { name: "* LOW Inflam workup", val: loInflam, color: "limegreen" },
  ];

  const arrCreate = () => {
    const focalArr = focalDx.map((d, i) => ({
      name: "focalDx",
      val: d,
      color: "orange",
    }));
    const congenArr = congenRisk.map((r, i) => ({
      name: "congenRisk",
      val: r,
      color: "brown",
    }));
    return [...labelArrOrig, ...focalArr, ...congenArr];
  };
  const fullArr = arrCreate();

  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      {view !== "Start" && view !== "Age" && view !== "Disclaimer" && (
        <Accordion>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={handleClick}
            style={{
              color: "steelblue",
              fontSize: "1.1em",
              paddingBottom: "0em",
              paddingTop: "0em",
            }}>
            <Icon name="dropdown" size="big" color="teal" />
            Open/Close Label Tags
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <div style={{ textAlign: "left" }}>
              {fullArr.map(
                (l, i) =>
                  l.val &&
                  l.val !== "deferred" &&
                  l.val !== "Hypothermia: episodes of Temp <36.5C" && (
                    <Label
                      size="small"
                      tag
                      // color={l.color}
                      key={i}
                      style={
                        view === "MgmtStart"
                          ? {
                              margin: "0.4em 0.6em",
                              fontSize: "0.8em",
                              color: "white",
                              backgroundColor: l.color,
                            }
                          : {
                              margin: "0.4em 0.8em",
                              fontSize: "0.8em",
                              color: "white",
                              backgroundColor: l.color,
                            }
                      }>
                      {(l.color === "red" && l.name !== "CSF") ||
                      eqOne(l.val, [loInflam, penias, hsvRisk, hypotherm, imm])
                        ? l.name
                        : l.val}
                    </Label>
                  )
              )}
            </div>
          </Accordion.Content>
        </Accordion>
      )}
      {view !== "Start" && view !== "Disclaimer" && (
        <>
          <Label
            style={{ margin: "0.4em", fontSize: "0.7em", cursor: "pointer" }}
            size="small"
            basic
            color="blue"
            onClick={handleReset}>
            <Icon name="repeat" />
            RESET ALL
          </Label>
          {/* <Modal
            closeIcon
            closeOnDocumentClick
            closeOnDimmerClick
            size="large"
            onClose={() => setModalOpen(false)}
            onOpen={() => setModalOpen(true)}
            open={modalOpen}
            trigger={<Button>Show Modal</Button>}>
            <Modal.Content>
              <Image size="massive" centered fluid src={well_7to21} wrapped />
            </Modal.Content>
          </Modal> */}
        </>
      )}
    </>
  );
};

export default LabelView;
