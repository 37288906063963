import acronyms from "../../utils/acronyms";
import { eqOne, allTrue } from "../../fxns/operatorFxns";

const a7to21_Fluid_SLo = (
  x,
  bool,
  fullStateObj,
  dispatcher,
  workupDispatcher,
  inflamDispatcher,
  close,
  histPush
) => {
  const {
    core: {
      coreBase: { age, feverInflam, congenRisk, focalDx, ill, hsvRisk, view },
    },
    derFever: {
      derFeverBase: { fluidWorkup, fluidInflamm },
    },
  } = fullStateObj;
  const {
    Y,
    YLP,
    MLP,
    NNLP,
    PLP,
    DEP,
    illa,
    bro,
    mayNoAbx,
    abx,
    mayAbx,
    nnAbx,
    min24,
    adm,
    dLP,
    D,
    trau,
    unsuc,
  } = acronyms;

  // Below for Urine and Blood only:
  const fluidLogic = (fluid) => {
    const otherMaker = (fluid) => {
      if (fluid === "Urine") {
        return "Blood";
      } else {
        return "Urine";
      }
    };
    const other = otherMaker(fluid);
    bool === false && inflamDispatcher(fluid, false);
    bool === true && inflamDispatcher(fluid, true);
    bool === true && inflamDispatcher("loInflam", false);

    if (bool === true && eqOne(fluidWorkup["CSF"], [MLP, DEP, D])) {
      if (
        (focalDx.includes(bro) || focalDx.includes("COVID positive")) &&
        ill !== illa
      ) {
        workupDispatcher("CSF", MLP);
      } else {
        workupDispatcher("CSF", YLP);
        inflamDispatcher("CSF", null);
      }
    }

    if (focalDx.includes(bro) || focalDx.includes("COVID positive")) {
      bool === false &&
        fluidInflamm[other] === false &&
        fluidInflamm["CSF"] === null &&
        (feverInflam === null || feverInflam === false) &&
        ill !== illa &&
        !hsvRisk &&
        congenRisk.length === 0 &&
        workupDispatcher("CSF", NNLP);
    } else {
      bool === false &&
        fluidInflamm[other] === false &&
        fluidInflamm["CSF"] === null &&
        (feverInflam === null || feverInflam === false) &&
        ill !== illa &&
        !hsvRisk &&
        congenRisk.length === 0 &&
        workupDispatcher("CSF", MLP);
    }

    close(x.name);
  };

  const csfLogic = () => {
    bool === false && inflamDispatcher("CSF", false);
    if (bool === false || bool === D) {
      if (!fluidInflamm["Blood"] && !fluidInflamm["Urine"]) {
        inflamDispatcher("loInflam", true);
        dispatcher("view", "MgmtStart");
        histPush();
      }
    }

    bool === true && inflamDispatcher("CSF", true);
    bool === true && inflamDispatcher("loInflam", false);
    (bool === trau || bool === unsuc || bool === D) &&
      inflamDispatcher("CSF", bool);
    if (
      fluidInflamm["Urine"] !== null &&
      fluidInflamm["Blood"] !== null &&
      fluidInflamm["CSF"] !== null &&
      fluidInflamm["CSF"] !== PLP
    ) {
      // inflamDispatcher("loInflam", true);
      dispatcher("view", "MgmtStart");
      histPush();
    }
    close(x.name);
  };

  const peniaLogic = () => {
    dispatcher("penias", bool);
    bool === true && dispatcher("hsvRisk", true);
  };

  const logicSwitcher = () => {
    return (
      (x.name === "penias" && peniaLogic()) ||
      (x.name === "hsvRisk" && dispatcher("hsvRisk", true)) ||
      (x.name === "Urine" && fluidLogic("Urine")) ||
      (x.name === "Blood" && fluidLogic("Blood")) ||
      (x.name === "CSF" && csfLogic())
    );
  };
  logicSwitcher();
};

export default a7to21_Fluid_SLo;
