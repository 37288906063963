import React, { useEffect } from "react";
// import history from "../../history";
import { Container } from "semantic-ui-react";
import { useSelector } from "react-redux";
import history from "../../history";
import LabelView from "../views/LabelView";
// import PgHeaderView from "../views/Headers/PgHeaderView";
import OldView from "../views/OldView";
import LT7View from "../views/LT7View";

const OldLandPg = () => {
  const { view } = useSelector((state) => state.core.coreBase);
  const histPush = (passed) => {
    history.push({
      pathname: passed,
      // state: { v },
    });
  };

  useEffect(() => {
    view === "Start" &&
      history.push({
        pathname: "/",
        // state: { v },
      });
  });

  return (
    <Container text style={{ marginTop: "5em", textAlign: "center" }}>
      <LabelView />
      {view === "OldStart" && <OldView />}
      {view === "LT7Start" && <LT7View />}
    </Container>
  );
};

export default OldLandPg;
