import acronyms from "../../utils/acronyms";
import a29to60_Inputs_SLo_Bro from "./a29-60_Inputs_SLo_Bro";
const a29to60_Inputs_SLo = (fullStateObj, dispatcher) => {
  const {
    core: {
      coreBase: {
        age,
        feverInflam,
        congenRisk,
        focalDx,
        ill,
        imm,
        penias,
        hypotherm,
        hsvRisk,
        view,
      },
    },
    derFever: {
      derFeverBase: {
        fluidWorkup: { Urine: uWU, Blood: bWU, CSF: cWU },
        fluidInflamm: { Urine: uI, Blood: bI, CSF: cI, loInflam },
        mngRecs: { Therapy, Dispo, Course },
      },
    },
  } = fullStateObj;

  const {
    Y,
    YLP,
    MLP,
    NNLP,
    NN,
    PLP,
    DEP,
    illa,
    bro,
    cov,
    vesicles,
    mayNoAbx,
    abx,
    mayAbx,
    nnAbx,
    min24,
    adm,
    dLP,
    trau,
    unsuc,
    discWiPeds,
  } = acronyms;

  const dispatchinator = (u, b, c) => {
    return dispatcher("fluidWorkup", {
      Urine: u,
      Blood: b,
      CSF: c,
    });
  };

  const arrIncluCat1to6 =
    focalDx.some((f) =>
      [
        "Focal Bacterial infection",
        "Skin or Oral vesicles",
        "Seizures",
      ].includes(f)
    ) ||
    congenRisk.some((c) => [
      "<37 wk EGA at birth",
      "Maternal Chorio or Fever within 48 hours of birth",
      "Neonatal course complicated by surgery or infection",
      "Maternal history genital HSV lesions",
      "Immunocompromised",
      "Congenital or Chromosomal abnormality",
      "Medically fragile",
    ]);

  const logicizer = () => {
    if (
      ill === illa ||
      focalDx.includes("Seizures") ||
      focalDx.includes(vesicles)
    ) {
      dispatchinator(Y, Y, YLP);
    } else {
      focalDx.includes(bro)
        ? a29to60_Inputs_SLo_Bro(fullStateObj, dispatcher)
        : imm && !arrIncluCat1to6
        ? dispatchinator(NN, NN, NNLP)
        : imm && arrIncluCat1to6
        ? dispatchinator(discWiPeds, discWiPeds, discWiPeds)
        : arrIncluCat1to6
        ? dispatchinator(Y, Y, YLP)
        : focalDx.includes(cov)
        ? dispatchinator(Y, Y, discWiPeds)
        : dispatchinator(Y, Y, DEP);
    }
  };
  logicizer();
};

export default a29to60_Inputs_SLo;
