export const eqOne = (operand, arr) => {
  let boolArr = arr.map((x, i) => {
    if (operand === x) {
      return true;
    } else {
      return false;
    }
  });
  if (boolArr.includes(true)) {
    return true;
  } else {
    return false;
  }
};

export const allTrue = (operand, arr) => {
  let boolArr = arr.map((x, i) => {
    if (operand !== x) {
      return true;
    } else {
      return false;
    }
  });
  if (boolArr.includes(true)) {
    return false;
  } else {
    return true;
  }
};
