import React from "react";
// import history from "../../history";
import { Header, Divider } from "semantic-ui-react";
import InputsBtn from "../elements/InputsBtn";

const DisclaimerView = () => {
  return (
    <div>
      <Header as="h1">Disclaimer</Header>
      <p style={{ textAlign: "left", fontSize: "0.9em" }}>
        You should treat ill-appearing infants based on their individual
        symptoms, the suspected cause of their illness, and according to your
        institution’s policy for managing infant fever. The following
        recommendations may be generally applicable in the initial evaluation of
        these infants and are provided for reference purposes only. None of the
        information provided is based on the characteristics of an individual
        infant and should not be relied upon in place of your personal medical
        judgment. Nothing contained in the content is intended to constitute
        professional advice for medical diagnosis or treatment.
      </p>
      <InputsBtn
        btnProps={{
          field: "view",
          val: "Age",
          content: "I Understand",
          // nextView: "Age",
          color: "steelblue",
          icon: "right arrow",
          labelPosition: "right",
          // histoPush: "/exclu",
        }}
      />
    </div>
  );
};

export default DisclaimerView;
