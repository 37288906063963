import acronyms from "../../utils/acronyms";
import a22to28_Mng_SLo_Bro from "./a22-28_Mng_SLo_Bro";
import { eqOne, allTrue } from "../../fxns/operatorFxns";
const a22to28_Mng_SLo = (fullStateObj, dispatcher) => {
  const {
    core: {
      coreBase: {
        age,
        feverInflam,
        congenRisk,
        focalDx,
        ill,
        penias,
        hypotherm,
        hsvRisk,
        view,
      },
    },
    derFever: {
      derFeverBase: {
        fluidWorkup: { Urine: uWU, Blood: bWU, CSF: cWU },
        fluidInflamm: { Urine: uI, Blood: bI, CSF: cI, loInflam },
        mngRecs: { Therapy, Dispo, Course },
      },
    },
  } = fullStateObj;

  const {
    Y,
    YLP,
    MLP,
    NNLP,
    NN,
    PLP,
    DEP,
    illa,
    bro,
    cov,
    mayNoAbx,
    abx,
    abxPleo,
    abxAcyclo,
    hsvStudies,
    abxHsvRiskCongen,
    mayAbx,
    nnAbx,
    min24,
    min24Acyclo,
    posGmStain,
    adm,
    dLP,
    trau,
    unsuc,
    discWiPeds,
  } = acronyms;

  const dispatchinator = (rx, disp, cou) => {
    return dispatcher("mngRecs", {
      Therapy: rx,
      Dispo: disp,
      Course: cou,
    });
  };

  const logicizer = () => {
    if (cI === "posGmStain") {
      dispatchinator(abx, adm, posGmStain);
      return;
    }
    if (hsvRisk || penias || hypotherm || focalDx.includes("Seizures")) {
      dispatchinator(abxAcyclo, adm, min24Acyclo);
      return;
    }
    if (ill === illa || (uWU === Y && bWU === Y && cWU === YLP)) {
      dispatchinator(abx, adm, min24);
      return;
    }
    if (focalDx.includes(bro) || focalDx.includes("COVID positive")) {
      a22to28_Mng_SLo_Bro(fullStateObj, dispatcher);
      return;
    }

    // !zz YES Abx :
    if (congenRisk.length > 0) {
      hsvRisk || hypotherm || penias
        ? dispatchinator(abxAcyclo, adm, min24Acyclo)
        : dispatchinator(abx, adm, min24);
      return;
    }
    if (
      (loInflam === false || loInflam === null || feverInflam) &&
      (cI === trau || cI === unsuc)
    ) {
      dispatchinator(abx, adm, min24);
      return;
    }
    if (cI === true) {
      dispatchinator(abxPleo, adm, min24);
      return;
    }

    // !zz uI or bI only (and cI false cuz above returns) :
    if (uI === true || bI === true) {
      dispatchinator(abx, adm, min24);
      return;
    }

    //  !zz MAY WITHOLD Abx :
    if (feverInflam && ![uI, bI, cI].includes(true)) {
      dispatchinator(mayNoAbx, adm, min24);
      return;
    }

    // !zz NEED NOT Abx :
    if ((loInflam || loInflam === null) && !feverInflam) {
      dispatchinator(nnAbx, adm, min24);
      return;
    }
    console.log("a22to28_Mng_SLo logicizer NoFired");
  };
  logicizer();
};

export default a22to28_Mng_SLo;
