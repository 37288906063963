/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import history from "../../history";
import {
  Icon,
  List,
  Button,
  Modal,
  Header,
  ListDescription,
} from "semantic-ui-react";
import InputsBtn from "../elements/InputsBtn";
import { useDispatch } from "react-redux";
import { changeState } from "../../1actions/core_actions";
import { changeInflamState } from "../../1actions/der_fever_actions";
import acronyms from "../../utils/acronyms";
import { eqOne, allTrue } from "../../fxns/operatorFxns";

const NoFluidBtnsView = ({ result }) => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  const {
    core: {
      coreBase: { age, congenRisk, focalDx, ill, view, hsvRisk },
    },
    derFever: {
      derFeverBase: { fluidWorkup, fluidInflamm },
    },
  } = useSelector((state) => state);

  const fluids = [
    {
      name: "Urine",
      icon: "flask",
      color: "yellow",
      moCon: "Results of Urine study",
    },
    {
      name: "Blood",
      icon: "syringe",
      color: "red",
      moCon: "Results of Blood study",
    },
    {
      name: "CSF",
      icon: "lightning",
      color: "purple",
      moCon: "Results of CSF study",
    },
  ];

  const {
    Y,
    YLP,
    MLP,
    NNLP,
    NN,
    PLP,
    DEP,
    illa,
    bro,
    mayNoAbx,
    M,
    abx,
    mayAbx,
    nnAbx,
    min24,
    adm,
    dLP,
    D,
    trau,
    unsuc,
    discWiPeds,
  } = acronyms;

  return (
    <div>
      {result === "full" && !hsvRisk ? (
        <InputsBtn
          btnProps={{
            field: "view",
            val: "HsvAssess",
            content: "Next Step: - Click here to evaluate for HSV flags",
            // nextView: "Age",
            color: "orange",
            icon: "right arrow",
            labelPosition: "right",
            // histoPush: "/exclu",
          }}
        />
      ) : (
        <InputsBtn
          btnProps={{
            field: "view",
            val: "MgmtStart",
            content: "Confirm and proceed to Management",
            // nextView: "Age",
            color: "green",
            icon: "right arrow",
            labelPosition: "right",
            histoPush: "/mgmtLand",
          }}
        />
      )}
      <List style={{ textAlign: "left", verticalAlign: "middle" }}>
        {fluids.map((x, i) => (
          <List.Item
            key={i}
            style={{ margin: "1em 0 0 1em", fontSize: "1.0em" }}>
            <Icon
              name={x.icon}
              color={x.color}
              size="large"
              style={{ opacity: "1.0 " }}
            />
            <List.Content>
              {/* // ! zz Text next to Icon: */}
              <List.Header style={{ opacity: "1.0", color: "steelblue" }}>
                {`${x.name}: `}
              </List.Header>
              {fluidInflamm[x.name] === null ? (
                <List.Description
                  style={
                    fluidWorkup[x.name] === Y || fluidWorkup[x.name] === YLP
                      ? { fontSize: "12px" }
                      : {
                          fontSize: "12px",
                          fontStyle: "italic",
                        }
                  }>
                  {/* // ! zz Text below FluidName: */}
                  {x.name === "CSF" || fluidWorkup["Urine"] === discWiPeds
                    ? fluidWorkup[x.name]
                    : `${fluidWorkup[x.name]} obtain`}
                </List.Description>
              ) : null}
            </List.Content>
          </List.Item>
        ))}
      </List>
    </div>
  );
};

export default NoFluidBtnsView;
