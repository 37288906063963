import React from "react";
// import history from "../../history";
import { Header, Divider } from "semantic-ui-react";
import InputsBtn from "../elements/InputsBtn";

const LandView = () => {
  return (
    <div>
      <p>Infant fever defined by Rectal Temp:</p>
      <Header as="h1">&#8805;100.4F / &#8805;38.0C</Header>
      <InputsBtn
        btnProps={{
          field: "view",
          val: "Disclaimer",
          content: "Begin",
          // nextView: "Disclaimer",
          color: "steelblue",
          icon: "right arrow",
          labelPosition: "right",
          // histoPush: "/exclu",
        }}
      />
    </div>
  );
};

export default LandView;
