import { combineReducers } from "redux";
import coreBase from "./core_base_reducer";

// import { persistReducer } from "redux-persist";
//import { hardSet } from "redux-persist/lib/stateReconciler/hardSet";

/* import storage from "redux-persist/lib/storage";
const persistConfig = {
  key: "core",
  storage: storage,
  stateReconciler: hardSet,
  // blacklist: ["coreBase"],
}; */

const coreReducer = combineReducers({
  coreBase,
});

// export default persistReducer(persistConfig, coreReducer);

// ** Use below to reset state, without Persist:
export default coreReducer;
