/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import history from "../../history";
import {
  Icon,
  List,
  Button,
  Modal,
  Header,
  ListDescription,
} from "semantic-ui-react";
import InputsBtn from "../elements/InputsBtn";
import { useDispatch } from "react-redux";
import { changeState } from "../../1actions/core_actions";
import { changeInflamState } from "../../1actions/der_fever_actions";
import JpModal from "../elements/JpModal";
import acronyms from "../../utils/acronyms";
import { eqOne, allTrue } from "../../fxns/operatorFxns";

const FluidsView = () => {
  const [openUrine, setOpenUrine] = useState(false);
  const [openBlood, setOpenBlood] = useState(false);
  const [openCSF, setOpenCSF] = useState(false);
  const dispatch = useDispatch();
  const opener = (x) => {
    switch (x) {
      case "Urine":
        setOpenUrine(true);
        break;
      case "Blood":
        setOpenBlood(true);
        break;
      case "CSF":
        setOpenCSF(true);
        break;
      default:
        return;
    }
  };
  const closer = (x) => {
    switch (x) {
      case "Urine":
        setOpenUrine(false);
        break;
      case "Blood":
        setOpenBlood(false);
        break;
      case "CSF":
        setOpenCSF(false);
        break;
      default:
        return;
    }
  };

  const {
    core: {
      coreBase: { age, congenRisk, focalDx, ill, view },
    },
    derFever: {
      derFeverBase: { fluidWorkup, fluidInflamm },
    },
  } = useSelector((state) => state);

  const fluids = [
    {
      name: "Urine",
      icon: "flask",
      color: "yellow",
      moCon: "Results of Urine study",
      open: openUrine,
    },
    {
      name: "Blood",
      icon: "syringe",
      color: "red",
      moCon: "Results of Blood study",
      open: openBlood,
    },
    {
      name: "CSF",
      icon: "lightning",
      color: "purple",
      moCon: "Results of CSF study",
      open: openCSF,
    },
  ];

  const {
    Y,
    YLP,
    MLP,
    NNLP,
    NN,
    PLP,
    DEP,
    illa,
    bro,
    mayNoAbx,
    M,
    abx,
    mayAbx,
    nnAbx,
    min24,
    adm,
    dLP,
    D,
    trau,
    unsuc,
    discWiPeds,
  } = acronyms;

  const histPush = () => {
    if (fluidInflamm["CSF"] === true) {
      dispatch(changeState("view", "GmStain"));
    } else {
      dispatch(changeState("view", "MgmtStart"));
      history.push({
        pathname: "/mgmtLand",
        // state: { v },
      });
    }
  };

  const txtMaker = (passed) => {
    const txt = () => {
      return passed === "may"
        ? "'MAY' can be interpreted as 'Consider' and will depend on specific patient circumstances. Consult Peds for further recs"
        : passed === "nnlp"
        ? "'NEED NOT' can be interpreted as 'Usually not indicated"
        : null;
    };
    return (
      <>
        <p
          style={{
            color: "steelblue",
            fontStyle: "italic",
            opacity: "0.5",
            fontSize: "1.0em",
            margin: "0.4em 0.1em",
          }}>
          {txt()}
        </p>
      </>
    );
  };

  useEffect(() => {
    if (
      fluidInflamm["Urine"] !== null &&
      fluidInflamm["Blood"] !== null &&
      fluidInflamm["CSF"] !== null &&
      fluidInflamm["CSF"] !== "planningLP"
    ) {
      histPush();
    }
  }, [fluidInflamm]);

  return (
    <div>
      <List style={{ textAlign: "left", verticalAlign: "middle" }}>
        {fluids.map((x, i) => (
          <List.Item
            key={i}
            style={{ margin: "1em 0 0 1em", fontSize: "1.0em" }}>
            <List.Content floated="right">
              {eqOne(fluidWorkup[x.name], [
                Y,
                YLP,
                MLP,
                NNLP,
                "May",
                NN,
                discWiPeds,
                "Consider",
              ]) && fluidInflamm[x.name] === null ? (
                // ! zz *** JpModal ***
                <JpModal x={x} open={opener} close={closer} />
              ) : (
                // ! zz Text when Modal Btn gone:
                <List.Description
                  style={
                    fluidInflamm[x.name] && fluidInflamm[x.name] !== dLP
                      ? {
                          fontWeight: "bold",
                          color: "red",
                          marginRight: "1em",
                        }
                      : { fontStyle: "italic", marginRight: "1em" }
                  }>
                  {eqOne(fluidWorkup[x.name], [
                    DEP,
                    "Do NOT",
                    "Workup and Rx per accepted standards for the Focal Infection",
                  ])
                    ? null
                    : fluidInflamm[x.name] === D
                    ? "Deferred"
                    : fluidInflamm[x.name] === trau
                    ? "Traumatic LP"
                    : fluidInflamm[x.name] === unsuc
                    ? "Unsuccessful LP"
                    : fluidInflamm[x.name] === true
                    ? `HIGH risk ${x.name}`
                    : `Low risk ${x.name}`}
                </List.Description>
              )}
            </List.Content>
            <Icon
              name={x.icon}
              color={x.color}
              size="large"
              style={
                fluidWorkup[x.name] === Y || fluidWorkup[x.name] === YLP
                  ? { opacity: "1.0" }
                  : { opacity: "0.1" }
              }
            />
            <List.Content>
              {/* // ! zz Text next to Icon: */}
              <List.Header
                style={
                  fluidWorkup[x.name] === Y || fluidWorkup[x.name] === YLP
                    ? { opacity: "1.0", color: "steelblue" }
                    : { opacity: "0.3" }
                }>
                {`${x.name}: `}
              </List.Header>
              {fluidInflamm[x.name] === null ? (
                <List.Description
                  style={
                    fluidWorkup[x.name] === Y || fluidWorkup[x.name] === YLP
                      ? { fontSize: "12px" }
                      : {
                          fontSize: "12px",
                          fontStyle: "italic",
                        }
                  }>
                  {/* // ! zz Text below FluidName: */}
                  {(x.name === "CSF" && fluidWorkup["CSF"] !== "Do NOT") ||
                  eqOne(fluidWorkup[x.name], [
                    DEP,
                    discWiPeds,
                    "Consider",
                    "Workup and Rx per accepted standards for the Focal Infection",
                  ])
                    ? fluidWorkup[x.name]
                    : `${fluidWorkup[x.name]} obtain`}
                </List.Description>
              ) : null}
            </List.Content>
            {eqOne(fluidWorkup[x.name], [MLP, M])
              ? txtMaker("may")
              : x.name === "CSF" && fluidWorkup["CSF"] === NNLP
              ? txtMaker("nnlp")
              : null}
            {x.name === "CSF" &&
              (fluidInflamm["Blood"] === true ||
                fluidInflamm["Urine"] === true) &&
              !bro && (
                <p
                  style={{
                    fontStyle: "italic",
                    color: "red",
                    marginTop: " 0.4em",
                  }}>
                  {" "}
                  !! LP attempt required due to hi risk Blood or Urine results
                </p>
              )}
          </List.Item>
        ))}
      </List>
    </div>
  );
};

export default FluidsView;
