import { eqOne } from "../../fxns/operatorFxns";
import acronyms from "../../utils/acronyms";
import a29to60_Mng_SLo_Bro from "./a29-60_Mng_SLo_Bro";
const a29to60_Mng_SLo = (fullStateObj, dispatcher) => {
  const {
    core: {
      coreBase: {
        age,
        feverInflam,
        congenRisk,
        focalDx,
        ill,
        penias,
        hypotherm,
        hsvRisk,
        view,
      },
    },
    derFever: {
      derFeverBase: {
        fluidWorkup: { Urine: uWU, Blood: bWU, CSF: cWU },
        fluidInflamm: { Urine: uI, Blood: bI, CSF: cI, loInflam },
        mngRecs: { Therapy, Dispo, Course },
      },
    },
  } = fullStateObj;

  const {
    Y,
    YLP,
    MLP,
    NNLP,
    NN,
    PLP,
    DEP,
    illa,
    bro,
    mayNoAbx,
    abx,
    abxPleo,
    abxAcyclo,
    hsvStudies,
    abxHsvRiskCongen,
    mayAbx,
    nnAbx,
    min24,
    min24Acyclo,
    posGmStain,
    adm,
    dLP,
    trau,
    unsuc,
    hospOrHome,
    abxParOrOral,
    oralAbx,
    obsHome,
    obsHomeUnlessResp,
    fu12to24,
    fu24to36,
  } = acronyms;

  const dispatchinator = (rx, disp, cou) => {
    return dispatcher("mngRecs", {
      Therapy: rx,
      Dispo: disp,
      Course: cou,
    });
  };

  const logicizer = () => {
    if (cI === "posGmStain") {
      dispatchinator(abx, adm, posGmStain);
      return;
    }
    if (hsvRisk || penias) {
      dispatchinator(abxAcyclo, adm, min24Acyclo);
      return;
    }
    if (ill === illa) {
      dispatchinator(abx, adm, min24);
      return;
    }
    if (focalDx.includes(bro)) {
      a29to60_Mng_SLo_Bro(fullStateObj, dispatcher);
      return;
    }

    // ! zz HOSP Abx :
    if (congenRisk.length > 0) {
      hsvRisk
        ? dispatchinator(abxAcyclo, adm, min24)
        : dispatchinator(abx, adm, min24);
      return;
    }
    if (cI === true || (bI && cI === trau)) {
      dispatchinator(abx, adm, min24);
      return;
    }

    // ! zz HOSP or HOME Abx :
    if ((bI && cI !== true && cI !== trau && !uI) || (bI && cI === unsuc)) {
      dispatchinator(abx, hospOrHome, fu12to24);
      return;
    }

    // ! zz HOME PR or PO Abx :
    if (bI && cI !== true && cI !== trau && uI) {
      dispatchinator(abxParOrOral, obsHome, fu12to24);
      return;
    }

    //  ! zz HOME PO Abx :
    if (!bI && cI !== true && uI) {
      dispatchinator(oralAbx, obsHome, fu12to24);
      return;
    }

    // ! zz HOME NNAbx :
    if (!bI && cI !== true && !uI) {
      dispatchinator(nnAbx, obsHome, fu24to36);
      return;
    }
  };
  logicizer();
};

export default a29to60_Mng_SLo;
