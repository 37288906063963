/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
// import history from "../../history";
import { useSelector, useDispatch } from "react-redux";
import MngView from "./MngView";
import mngLogic from "../../logics/mngLogic";
import { changeDerFeverState } from "../../1actions/der_fever_actions";

const MgmtStartView = () => {
  const dispatch = useDispatch();
  const fullStateObj = useSelector((state) => state);

  const dispatcher = (field, value) => {
    dispatch(changeDerFeverState(field, value));
  };

  const mngLogicFxn = (fullStateObj) => {
    mngLogic(fullStateObj, dispatcher);
  };

  useEffect(() => {
    mngLogicFxn(fullStateObj);
  }, []);

  return (
    <div>
      <MngView />
    </div>
  );
};

export default MgmtStartView;
