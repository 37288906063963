import React, { useEffect } from "react";
// import history from "../../history";
import { Container } from "semantic-ui-react";
import { useSelector } from "react-redux";
import history from "../../history";
import LabelView from "../views/LabelView";
// import PgHeaderView from "../views/PgHeaderView";
import MgmtHeaderView from "../views/Headers/MgmtHeaderView";
import MgmtStartView from "../views/MgmtStartView";

const MgmtLandPg = () => {
  const { view } = useSelector((state) => state.core.coreBase);
  const histPush = (passed) => {
    history.push({
      pathname: passed,
      // state: { v },
    });
  };
  useEffect(() => {
    view === "Start" && histPush("/");
  });

  return (
    <Container text style={{ marginTop: "5em", textAlign: "center" }}>
      <LabelView />
      <MgmtHeaderView />
      {view === "MgmtStart" && <MgmtStartView />}
    </Container>
  );
};

export default MgmtLandPg;
