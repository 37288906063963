import acronyms from "../../utils/acronyms";
import a7to21_Inputs_SLo_Bro from "./a7to21_Inputs_SLo_Bro";
const a7to21_Inputs_SLo = (fullStateObj, dispatcher) => {
  const {
    core: {
      coreBase: {
        age,
        feverInflam,
        congenRisk,
        focalDx,
        ill,
        penias,
        hypotherm,
        hsvRisk,
        view,
      },
    },
    derFever: {
      derFeverBase: {
        fluidWorkup: { Urine: uWU, Blood: bWU, CSF: cWU },
        fluidInflamm: { Urine: uI, Blood: bI, CSF: cI, loInflam },
        mngRecs: { Therapy, Dispo, Course },
      },
    },
  } = fullStateObj;

  const {
    Y,
    YLP,
    MLP,
    NNLP,
    PLP,
    DEP,
    illa,
    bro,
    cov,
    vesicles,
    mayNoAbx,
    abx,
    mayAbx,
    nnAbx,
    min24,
    adm,
    dLP,
    trau,
    unsuc,
  } = acronyms;

  const dispatchinator = (CSFpass) => {
    return dispatcher("fluidWorkup", {
      Urine: Y,
      Blood: Y,
      CSF: CSFpass,
    });
  };

  const logicizer = () => {
    ill === illa || focalDx.includes("Seizures") || focalDx.includes(vesicles)
      ? dispatchinator(YLP)
      : focalDx.includes(bro) || focalDx.includes("COVID positive")
      ? a7to21_Inputs_SLo_Bro(fullStateObj, dispatcher)
      : feverInflam === true ||
        loInflam === false ||
        congenRisk.length > 0 ||
        focalDx.includes(vesicles)
      ? dispatchinator(YLP)
      : focalDx.includes("Focal Bacterial infection")
      ? dispatcher("fluidWorkup", {
          Urine: "Workup and Rx per accepted standards for the Focal Infection",
          Blood: "Workup and Rx per accepted standards for the Focal Infection",
          CSF: "Workup and Rx per accepted standards for the Focal Infection",
        })
      : loInflam !== false && uI !== null && bI !== null && cI === null
      ? dispatchinator(MLP)
      : feverInflam !== true
      ? dispatchinator(DEP)
      : ill !== illa && feverInflam !== true
      ? dispatchinator(NNLP)
      : // this will Default fire if no prior Return:
        console.log("No logicizer fire in a7to21_Inputs_SLo");
  };
  logicizer();
};

export default a7to21_Inputs_SLo;
