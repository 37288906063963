import { combineReducers } from "redux";
import derFeverBase from "./der_fever_base_reducer";

// import { persistReducer } from "redux-persist";
// import { hardSet } from "redux-persist/lib/stateReconciler/hardSet";

/* import storage from "redux-persist/lib/storage";
const persistConfig = {
  key: "derFever",
  storage: storage,
  stateReconciler: hardSet,
  // whitelist: ["derFever"],
}; */

const derFeverReducer = combineReducers({
  derFeverBase,
});

// export default persistReducer(persistConfig, derFeverReducer);

// ** Use below to reset state, without Persist:
export default derFeverReducer;
