import React, { useState, useEffect } from "react";
import { Divider, Header, Message } from "semantic-ui-react";
import InputsBtn from "../elements/InputsBtn";

const HsvAssessView = () => {
  const [nameArr, setNameArr] = useState([]);
  const addToArrFxn = (passed) => setNameArr([...nameArr, passed]);
  const hsvFlags = [
    {
      name: "Episode(s) of hypothermia <36.5C",
      field: "hypotherm",
      val: true,
    },
    {
      name: "Penias: WBC <5k or Platelets <100k",
      field: "penias",
      val: true,
    },
    {
      name: "CSF pleocytosis AND negative GramStain",
      field: "hsvRisk",
      val: true,
    },
    {
      name: "You should add AST and ALT to labs, and if elevated levels, click here",
      field: "hsvRisk",
      val: true,
    },
  ];

  return (
    <div>
      <Divider />
      <Message
        icon="bug"
        header="In addition to a full bacterial workup of all fluids, this infant may be at risk for HSV"
        // content="Content heree"
        size="mini"
        floating
        color="orange"
        style={{ textAlign: "left" }}
      />
      <p
        style={{
          textAlign: "center",
          fontSize: "0.9em",
          color: "gray",
          marginBottom: "0em",
        }}>
        Enter whether any of the following HSV risks are true for this infant:
      </p>
      {hsvFlags.map(
        (x, i) =>
          !nameArr.includes(x.name) && (
            <InputsBtn
              btnProps={{
                field: x.field,
                val: x.val,
                content: x.name,
                color: "orange",
              }}
              addToHsvVuState={() => addToArrFxn(x.name)}
            />
          )
      )}
      <InputsBtn
        btnProps={{
          field: "view",
          val: "MgmtStart",
          content:
            "None of these risks identified OR All risks have been input",
          // nextView: "MgmtStart",
          color: "green",
          icon: "arrow right",
          labelPosition: "right",
          histoPush: "/mgmtLand",
        }}
      />
    </div>
  );
};

export default HsvAssessView;
