import React from "react";
// import history from "../../history";
import { List } from "semantic-ui-react";
import InputsBtn from "../elements/InputsBtn";
import { useSelector } from "react-redux";
import { eqOne } from "../../fxns/operatorFxns";

const CongenView = () => {
  const { congenRisk, age } = useSelector((state) => state.core.coreBase);
  const congenBaseOpt = [
    "<37 wk EGA at birth",
    "Immunocompromised",
    "Neonatal course complicated by surgery or infection",
    "Congenital or Chromosomal abnormality",
    "Medically fragile",
  ];
  const congenArrCreator = () => {
    return eqOne(age, ["7-21 days", "22-28 days"])
      ? [
          ...congenBaseOpt,
          "Maternal Chorio or Fever within 48 hours of birth",
          "Maternal history genital HSV lesions",
        ]
      : congenBaseOpt;
  };
  const fullArr = congenArrCreator();

  return (
    <div>
      <List>
        {fullArr.map(
          (x, i) =>
            !congenRisk.includes(x) && (
              <List.Item key={i} style={{ margin: "0em", fontSize: "0.8em" }}>
                <InputsBtn
                  btnProps={{
                    field: "congenRisk",
                    val: x,
                    content: x,
                    // nextView: "Focal",
                    color: "#F2711c",
                    icon: "white hand paper left",
                    labelPosition: "left",
                    // histoPush: "/exclu",
                  }}
                />
              </List.Item>
            )
        )}
      </List>
      <InputsBtn
        btnProps={{
          field: "congenRisk",
          val: null,
          content: "None, or All conditions input",
          // nextView: excluChecker(),
          nextView: "Focal",
          color: "green",
          icon: "arrow right",
          labelPosition: "right",
          // histoPush: "/exclu",
        }}
      />
    </div>
  );
};

export default CongenView;
