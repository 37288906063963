import React from "react";
// import history from "../../history";
import InputsBtn from "../elements/InputsBtn";

const AgeView = () => {
  const ageOpt = [
    // { days: "<7 days", color: "red" },
    { days: "7-21 days", color: "blue" },
    { days: "22-28 days", color: "steelblue" },
    { days: "29-60 days", color: "green" },
    { days: "61-90 days", color: "orange" },
    { days: "91-365 days", color: "violet" },
  ];

  // * Instead of below checker, nextView proceeds to "Ill" for all:
  /* const ageCheckr = (passedAge) => {
    return passedAge === "7-21 days" || passedAge === "22-28 days"
      ? "Congen"
      : passedAge === "<7 days"
      ? "LT7Start"
      : passedAge === "91-365 days"
      ? "OldStart"
      : "Imm";
  }; */

  return (
    <div>
      {ageOpt.map((x, i) => (
        <InputsBtn
          key={i}
          btnProps={{
            field: "age",
            val: x.days,
            content: x.days,
            // nextView: ageCheckr(x.days),
            nextView: "Ill",
            color: x.color,
            // icon: null,
            // histoPush: "/exclu",
          }}
        />
      ))}
    </div>
  );
};

export default AgeView;
