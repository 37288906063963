import React, { useEffect } from "react";
// import history from "../../history";
import { Container } from "semantic-ui-react";
import { useSelector } from "react-redux";
import history from "../../history";
import LabelView from "../views/LabelView";
import PgHeaderView from "../views/Headers/PgHeaderView";
import LandView from "../views/LandView";
import AgeView from "../views/AgeView";
import DisclaimerView from "../views/DisclaimerView";
import CongenView from "../views/CongenView.js";
import FocalView from "../views/FocalView";
import IllView from "../views/IllView";
import FeverInflamView from "../views/FeverInflamView";
import ExclusionsView from "../views/ExclusionsView";
import ImmView from "../views/ImmView";
import HsvAssessView from "../views/HsvAssessView";

const FeverLandPg = () => {
  const { view } = useSelector((state) => state.core.coreBase);
  const histPush = (passed) => {
    history.push({
      pathname: passed,
      // state: { v },
    });
  };
  const stayLand = () => {
    const landViews = [
      "Start",
      "Age",
      "FeverInflam",
      "Congen",
      // "Exclusions",
      "Focal",
      "Ill",
      "Imm",
      "HsvAssess",
    ];
    return landViews.includes(view) ? true : false;
  };

  useEffect(() => {
    view === "RecsStart" && histPush("/recsLand");
    view === "MgmtStart" && histPush("/mgmtLand");
    view === "OldStart" && histPush("/oldLand");
    view === "LT7Start" && histPush("/oldLand");
  });

  return (
    <Container text style={{ marginTop: "5em", textAlign: "center" }}>
      <LabelView />
      {stayLand() && <PgHeaderView />}
      {view === "Start" && <LandView />}
      {view === "Disclaimer" && <DisclaimerView />}
      {view === "Age" && <AgeView />}
      {view === "FeverInflam" && <FeverInflamView />}
      {view === "Congen" && <CongenView />}
      {view === "Focal" && <FocalView />}
      {view === "Ill" && <IllView />}
      {view === "Exclusions" && <ExclusionsView />}
      {view === "Imm" && <ImmView />}
      {view === "HsvAssess" && <HsvAssessView />}
    </Container>
  );
};

export default FeverLandPg;
