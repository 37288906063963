import React from "react";
import { Container, Header } from "semantic-ui-react";

const SecondPg = () => {
  return (
    <Container text style={{ marginTop: "5em" }}>
      <Header as="h1">SECOND page</Header>
      <p>This is a basic fixed menu template using fixed size containers.</p>
    </Container>
  );
};

export default SecondPg;
