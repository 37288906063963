import { eqOne } from "../../fxns/operatorFxns";
import acronyms from "../../utils/acronyms";
const a22to28_Mng_SLo_Bro = (fullStateObj, dispatcher) => {
  const {
    core: {
      coreBase: {
        age,
        feverInflam,
        congenRisk,
        focalDx,
        ill,
        penias,
        hypotherm,
        hsvRisk,
        view,
      },
    },
    derFever: {
      derFeverBase: {
        fluidWorkup: { Urine: uWU, Blood: bWU, CSF: cWU },
        fluidInflamm: { Urine: uI, Blood: bI, CSF: cI, loInflam },
        mngRecs: { Therapy, Dispo, Course },
      },
    },
  } = fullStateObj;

  const {
    Y,
    YLP,
    MLP,
    NNLP,
    PLP,
    DEP,
    illa,
    bro,
    mayNoAbx,
    abx,
    abxPleo,
    mayAbx,
    nnAbx,
    min24,
    min24Acyclo,
    adm,
    dLP,
    trau,
    unsuc,
    oralAbx,
    obsHomeUnlessResp,
    fu12to24,
    fu24to36,
    discWiPeds,
    cov,
  } = acronyms;

  const bro_dispatchinator = (rx, disp, cou) => {
    // console.log("MNG bro_dipatchinator fired: ", rx, disp, cou);
    return dispatcher("mngRecs", {
      Therapy: rx,
      Dispo: disp,
      Course: cou,
    });
  };

  const bro_logicizer = () => {
    const arrIncluCat1to6 =
      focalDx.some((f) =>
        [
          "Focal Bacterial infection",
          "Skin or Oral vesicles",
          "Seizures",
        ].includes(f)
      ) ||
      congenRisk.some((c) => [
        "<37 wk EGA at birth",
        "Maternal Chorio or Fever within 48 hours of birth",
        "Neonatal course complicated by surgery or infection",
        "Maternal history genital HSV lesions",
        "Immunocompromised",
        "Congenital or Chromosomal abnormality",
        "Medically fragile",
      ]);

    return cI === true
      ? bro_dispatchinator(abxPleo, adm, min24)
      : arrIncluCat1to6
      ? bro_dispatchinator(discWiPeds, discWiPeds, discWiPeds)
      : /* : focalDx.includes(cov)
      ? bro_dispatchinator(discWiPeds, discWiPeds, discWiPeds) */
      bI === true
      ? bro_dispatchinator(oralAbx, obsHomeUnlessResp, fu12to24)
      : uI === true
      ? bro_dispatchinator(oralAbx, obsHomeUnlessResp, fu12to24)
      : uI !== true && bI !== true
      ? bro_dispatchinator(nnAbx, obsHomeUnlessResp, fu24to36)
      : console.log("No MngSloBro logicizer option triggered");
  };
  bro_logicizer();
};

export default a22to28_Mng_SLo_Bro;
