import React from "react";
import { Button } from "semantic-ui-react";
// import fluidModalLogic from "../../logics/fluidModalLogic";

const ModalBtn = ({
  btnProps: {
    fluidModalResultClick,
    btnText,
    color,
    x,
    bool,
    fullStateObj,
    dispatcher,
    workupDispatcher,
    inflamDispatcher,
    close,
    histPush,
  },
}) => {
  return (
    <Button
      color={color}
      style={{ fontSize: "1.0em" }}
      onClick={() =>
        fluidModalResultClick(
          x,
          bool,
          fullStateObj,
          dispatcher,
          workupDispatcher,
          inflamDispatcher,
          close,
          histPush
        )
      }>
      {btnText}
    </Button>
  );
};

export default ModalBtn;
