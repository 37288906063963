import React, { useState } from "react";
import { Message, Accordion, Icon } from "semantic-ui-react";
import { useSelector } from "react-redux";
import acronyms from "../../../utils/acronyms";
import { eqOne } from "../../../fxns/operatorFxns";

const MgmtHeaderView = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };
  const {
    core: {
      coreBase: {
        age,
        feverInflam,
        congenRisk,
        focalDx,
        ill,
        penias,
        hypotherm,
        hsvRisk,
        view,
      },
    },
    derFever: {
      derFeverBase: {
        fluidWorkup: { Urine: uWU, Blood: bWU, CSF: cWU },
        fluidInflamm: { Urine: uI, Blood: bI, CSF: cI, loInflam },
        mngRecs: { Therapy, Dispo, Course },
      },
    },
  } = useSelector((state) => state);
  const {
    Y,
    YLP,
    MLP,
    NNLP,
    PLP,
    DEP,
    illa,
    bro,
    cov,
    vesicles,
    matLesions,
    mayNoAbx,
    abx,
    abxPleo,
    abxHsvRiskCongen,
    abxAcyclo,
    hsvStudies,
    mayAbx,
    nnAbx,
    min24,
    adm,
    dLP,
    trau,
    unsuc,
  } = acronyms;
  const Msg = (props) => {
    const { msgTxt, color = "grey", risk = null } = props;
    return (
      <Message
        header={
          msgTxt === "acycloCuz"
            ? `** ${acycloObj[msgTxt]}${risk}`
            : `** ${msgObj["Msg"][msgTxt]}`
        }
        // content={msgObj[view].content}
        size="mini"
        floating
        color={color}
        style={{ textAlign: "left" }}
      />
    );
  };

  const acycloTxt = {
    penias: "Blood penias",
  };

  const msgObj = {
    MgmtStart: {
      icon: "treatment",
      color: "green",
      hdr: "Recommended Management :",
      content: null,
    },
    Start: {
      icon: "treatment",
      color: "green",
      hdr: "Recommended Management :",
      content: null,
    },
    Msg: {
      ill: "All 'Ill appearing' infants require full workup and empiric antibiotics even if negative workup",
      abxCSFYes:
        "IF Pleocytosis, esp. if negative GmStain: Consider additional HSV Studies and initiating Acyclovir",
      hsvStudies:
        "HSV studies: CSF for HSV PCR, HSV PCR of serum and surface swabs: conjunctiva, NP, skin, vesicles if present, and rectal.",
      lpIfAbx:
        "If you plan to start antibiotics you should attempt LP first, or consider withholding anitibiotics instead",
      evenHot:
        "Even with elevated temp spike in this age, can withold antibiotics given all fluid results are low risk",
      focal:
        "Infants with Focal Bacterial Infection (e.g. cellulitis, omphalitis, septic arthritis, osteomyelitis) - Should be managed according to accepted standards for those diagnoses",
      covid:
        "Current data support management of COVID positive infants with fever in same manner as Bronchiolitis:",
    },
  };

  const acycloObj = {
    acycloCuz: `Consider Initiating HSV workup and coverage due to: `,
  };

  const messager = () => {
    const evenHot = () => {
      return (
        feverInflam &&
        !uI &&
        !bI &&
        !cI &&
        congenRisk.length === 0 &&
        Therapy !== abx &&
        Therapy !== abxAcyclo && <Msg msgTxt="evenHot" color="green" />
      );
    };
    const csfYesMsg = () => {
      return cI === true && <Msg msgTxt="abxCSFYes" color="red" />;
    };
    const illCk = () => {
      return ill === illa && <Msg msgTxt="ill" color="red" />;
    };
    const covidMsg = () => {
      return focalDx.includes(cov) && <Msg msgTxt="covid" color="yellow" />;
    };
    const focal = () => {
      return (
        focalDx.includes("Focal Bacterial infection") && (
          <Msg msgTxt="focal" color="yellow" />
        )
      );
    };
    const lpIfAbx = () => {
      return (
        eqOne(Therapy, [mayAbx, mayNoAbx, nnAbx]) && (
          <Msg msgTxt="lpIfAbx" color="red" />
        )
      );
    };
    const msgFxnArr = [
      illCk,
      evenHot,
      csfYesMsg,
      focal,
      lpIfAbx,
      acyclinator,
      hsvStudier,
      covidMsg,
    ];
    return msgFxnArr.map((f) => <>{f()}</>);
  };

  const hsvStudier = () => {
    return (
      (hsvRisk ||
        Therapy === abxAcyclo ||
        cI === true ||
        hypotherm ||
        penias ||
        focalDx.includes(vesicles) ||
        congenRisk.includes(matLesions)) &&
      hsvStudyAccord()
    );
  };
  const hsvStudyAccord = () => {
    return (
      <Accordion>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={handleClick}
          style={{
            color: "steelblue",
            fontSize: "1.1em",
            paddingBottom: "0em",
            paddingTop: "0em",
          }}>
          <Icon name="dropdown" size="big" color="teal" />
          Open/Close HSV studies:
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <Msg
            msgTxt="hsvStudies"
            color="yellow"
            style={{ paddingTop: "0em" }}
          />
        </Accordion.Content>
      </Accordion>
    );
  };
  const acyclinator = () => {
    return (
      Therapy !== mayNoAbx &&
      Therapy !== abxAcyclo &&
      (hypotherm ||
        penias ||
        focalDx.includes(vesicles) ||
        congenRisk.includes(matLesions)) && (
        <>
          <Msg
            msgTxt="acycloCuz"
            color="orange"
            risk={
              penias
                ? "Blood penias"
                : hypotherm
                ? "Hypothermia"
                : focalDx.includes(vesicles)
                ? "Vesicles found"
                : congenRisk.includes(matLesions)
                ? "Maternal history of genital HSV"
                : focalDx.includes("Seizures")
                ? "Seizures"
                : null
            }
          />
        </>
      )
    );
  };

  return (
    <>
      {msgObj[view] !== "Start" && (
        <Message
          icon={msgObj[view].icon}
          header={msgObj[view].hdr}
          content={msgObj[view].content}
          size="mini"
          floating
          color={msgObj[view].color}
        />
      )}
      {messager()}
    </>
  );
};

export default MgmtHeaderView;
