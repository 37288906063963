import acronyms from "../../utils/acronyms";
import { eqOne, allTrue } from "../../fxns/operatorFxns";

const a29to60_Fluid_SLo = (
  x,
  bool,
  fullStateObj,
  dispatcher,
  workupDispatcher,
  inflamDispatcher,
  close,
  histPush
) => {
  const {
    core: {
      coreBase: { age, feverInflam, congenRisk, focalDx, ill, hsvRisk, view },
    },
    derFever: {
      derFeverBase: {
        fluidWorkup: { CSF: cWU },
        fluidInflamm,
      },
    },
  } = fullStateObj;
  const {
    Y,
    YLP,
    MLP,
    NNLP,
    NN,
    PLP,
    DEP,
    illa,
    bro,
    mayNoAbx,
    abx,
    mayAbx,
    nnAbx,
    min24,
    adm,
    dLP,
    D,
    trau,
    unsuc,
  } = acronyms;

  const arrIncluCat1to6 =
    focalDx.some((f) =>
      [
        "Focal Bacterial infection",
        "Skin or Oral vesicles",
        "Seizures",
      ].includes(f)
    ) ||
    congenRisk.some((c) => [
      "<37 wk EGA at birth",
      "Maternal Chorio or Fever within 48 hours of birth",
      "Neonatal course complicated by surgery or infection",
      "Maternal history genital HSV lesions",
      "Immunocompromised",
      "Congenital or Chromosomal abnormality",
      "Medically fragile",
    ]);

  const urineLogic = () => {
    inflamDispatcher("Urine", bool);
    if (ill !== illa && !hsvRisk && !focalDx.includes(bro)) {
      bool === true &&
        fluidInflamm["Blood"] !== null &&
        workupDispatcher("CSF", NNLP);
      bool === false &&
        ill !== illa &&
        !hsvRisk &&
        eqOne(fluidInflamm["Blood"], [false]) &&
        workupDispatcher("CSF", NNLP);
    }
    if (ill !== illa && !hsvRisk && focalDx.includes(bro)) {
      bool === true && workupDispatcher("Blood", "May");
      bool === false && workupDispatcher("Blood", NN);
    }
    histPusher();
  };

  const bloodLogic = () => {
    inflamDispatcher("Blood", bool);
    if (ill !== illa && !hsvRisk && !arrIncluCat1to6) {
      bool === true && workupDispatcher("CSF", MLP);
      bool === false &&
        ill !== illa &&
        !hsvRisk &&
        fluidInflamm["Urine"] !== null &&
        workupDispatcher("CSF", NNLP);
    }
    hsvRisk && workupDispatcher("CSF", YLP);
    histPusher();
  };

  const csfLogic = () => {
    inflamDispatcher("CSF", bool);
    histPusher();
  };

  const peniaLogic = () => {
    dispatcher("penias", bool);
    bool === true && dispatcher("hsvRisk", true);
  };

  const histPusher = () => {
    if (
      fluidInflamm["Urine"] !== null &&
      fluidInflamm["Blood"] !== null &&
      fluidInflamm["CSF"] !== null
    ) {
      dispatcher("view", "MgmtStart");
      histPush();
    }
  };

  const logicSwitcher = () => {
    return (
      (x.name === "penias" && peniaLogic()) ||
      (x.name === "hsvRisk" && dispatcher("hsvRisk", true)) ||
      (x.name === "Urine" && urineLogic()) ||
      (x.name === "Blood" && bloodLogic()) ||
      (x.name === "CSF" && csfLogic())
    );
  };
  logicSwitcher();
};

export default a29to60_Fluid_SLo;
