import React, { useState } from "react";
import { useSelector } from "react-redux";
import history from "../../history";
import { useDispatch } from "react-redux";
import { Button, Modal, Header, Divider } from "semantic-ui-react";
import {
  changeInflamState,
  changeWorkupState,
} from "../../1actions/der_fever_actions";
import { changeState } from "../../1actions/core_actions";
import fluidModalLogic from "../../logics/fluidModalLogic";
import acronyms from "../../utils/acronyms";
import ModalBtn from "./ModalBtn";
import { eqOne } from "../../fxns/operatorFxns";

const JpModal = ({ x, open, close }) => {
  const fullStateObj = useSelector((state) => state);
  const {
    core: {
      coreBase: {
        age,
        feverInflam,
        congenRisk,
        focalDx,
        ill,
        penias,
        hsvRisk,
        view,
      },
    },
    derFever: {
      derFeverBase: { fluidWorkup, fluidInflamm },
    },
  } = fullStateObj;
  const dispatch = useDispatch();

  const dispatcher = (f, v) => {
    dispatch(changeState(f, v));
  };

  const workupDispatcher = (fluid, value) => {
    dispatch(changeWorkupState(fluid, value));
  };

  const inflamDispatcher = (fluid, value) => {
    dispatch(changeInflamState(fluid, value));
  };

  const histPush = () => {
    dispatch(changeState("view", "MgmtStart"));
    history.push({
      pathname: "/mgmtLand",
      // state: { v },
    });
  };

  const fluidModalResultClick = (
    x,
    bool,
    fullStateObj,
    dispatcher,
    workupDispatcher,
    inflamDispatcher,
    close,
    histPush
  ) => {
    fluidModalLogic(
      x,
      bool,
      fullStateObj,
      dispatcher,
      workupDispatcher,
      inflamDispatcher,
      close,
      histPush
    );
  };

  const inputText = (fluid) => {
    return (fluid === "CSF" && fluidWorkup["CSF"] !== YLP) ||
      (eqOne(fluid, ["Blood", "Urine"]) &&
        eqOne(fluidWorkup[fluid], ["May", NN, "Consider"]))
      ? "Decision"
      : "Input Result";
  };

  const csfBtnArr = [
    { btnText: "Negative", color: "green", bool: false },
    { btnText: "Positive", color: "red", bool: true },
    { btnText: "Traumatic", color: "orange", bool: "traumatic" },
    { btnText: "Unsuccessful", color: "orange", bool: "unsuccessful" },
  ];

  const {
    Y,
    YLP,
    MLP,
    NNLP,
    NN,
    PLP,
    DEP,
    illa,
    bro,
    mayNoAbx,
    M,
    abx,
    mayAbx,
    nnAbx,
    min24,
    adm,
    dLP,
    trau,
    unsuc,
    discWiPeds,
  } = acronyms;

  return (
    <div>
      {/* // ! zz Input Result Btn for Modal Exterior: */}
      {(x.name !== "CSF" ||
        (x.name === "CSF" && fluidWorkup["CSF"] !== DEP)) && (
        <Modal
          dimmer="blurring"
          closeIcon
          open={x.open}
          trigger={
            <Button color={x.color} style={{ opacity: "0.8" }} size="small">
              {inputText(x.name)}
            </Button>
          }
          onClose={() => close(x.name)}
          onOpen={() => open(x.name)}>
          {x.name === "Urine" ? (
            <Header
              icon={x.icon}
              content={x.moCon}
              style={{ color: "orange" }}
            />
          ) : (
            <Header
              icon={x.icon}
              content={x.moCon}
              style={{ color: x.color }}
            />
          )}
          {/*  // ! zz Penias Text: */}
          {!focalDx.includes("COVID positive") && (
            <Modal.Content>
              {x.name === "Urine" ||
              (x.name === "Blood" &&
                !eqOne(age, ["7-21 days", "22-28 days", "29-60 days"]))
                ? null
                : x.name === "Blood" && penias === null
                ? "Penias can indicate increased risk of HSV infection - Click below if WBC <5k and/or Platelets <100k:"
                : x.name === "CSF" &&
                  hsvRisk === null &&
                  eqOne(age, ["7-21 days", "22-28 days"])
                ? "If CSF Pleocytosis (>11-15 wbc), with NEGATIVE GramStain, then risk of HSV is greater likelihod - Confirm if this is true:"
                : x.name === "CSF" &&
                  hsvRisk === null &&
                  eqOne(age, ["29-60 days"])
                ? "If CSF Pleocytosis (>10 wbc), with NEGATIVE GramStain, then risk of HSV is greater likelihod - Confirm if this is true:"
                : null}
            </Modal.Content>
          )}
          {/* // ! zz Penias Btns: */}
          {x.name === "Blood" &&
            !focalDx.includes("COVID positive") &&
            penias === null &&
            eqOne(age, ["7-21 days", "22-28 days", "29-60 days"]) && (
              <Modal.Actions
                style={{ textAlign: "center", marginBottom: "0.2em" }}>
                <Button
                  color="green"
                  onClick={() =>
                    fluidModalResultClick(
                      { name: "penias" },
                      false,
                      fullStateObj,
                      dispatcher,
                      workupDispatcher,
                      inflamDispatcher,
                      close,
                      histPush
                    )
                  }>
                  No penias
                </Button>
                <Button
                  color="orange"
                  onClick={() =>
                    fluidModalResultClick(
                      { name: "penias" },
                      true,
                      fullStateObj,
                      dispatcher,
                      workupDispatcher,
                      inflamDispatcher,
                      close,
                      histPush
                    )
                  }>
                  YES penias found
                </Button>
              </Modal.Actions>
            )}
          {/* // ! zz HSV risk Btn for CSF: */}
          {x.name === "CSF" &&
            hsvRisk === null &&
            eqOne(age, ["7-21 days", "22-28 days", "29-60 days"]) && (
              <Modal.Actions style={{ textAlign: "center" }}>
                <Button
                  color="orange"
                  onClick={() =>
                    fluidModalResultClick(
                      { name: "hsvRisk" },
                      true,
                      fullStateObj,
                      dispatcher,
                      workupDispatcher,
                      inflamDispatcher,
                      close,
                      histPush
                    )
                  }>
                  Yes HSV risk
                </Button>
              </Modal.Actions>
            )}
          {((x.name === "Blood" && penias === null) ||
            (x.name === "CSF" && hsvRisk === null)) && <Divider />}
          {/* // ! zz Internal Modal Text, all fluids: */}
          <Modal.Content>
            {x.name === "Urine"
              ? "Positive Urine IF: WBC >4-9, LE pos or Nit pos"
              : x.name === "Blood"
              ? "HIGH risk Blood IF: WBC <5k or >15k, Bands >1500"
              : x.name === "CSF" && eqOne(age, ["7-21 days", "22-28 days"])
              ? "HIGH risk CSF IF: WBC >11-15 or Positive GmStain"
              : x.name === "CSF" && !eqOne(age, ["7-21 days", "22-28 days"])
              ? "HIGH risk CSF IF: WBC >10 or Positive GmStain"
              : null}
          </Modal.Content>
          {/* // ! zz Internal Modal Buttons, inclu CSF: */}
          <Modal.Actions style={{ textAlign: "center" }}>
            {x.name === "CSF" &&
              csfBtnArr.map((btn, i) => (
                <ModalBtn
                  btnProps={{
                    fluidModalResultClick,
                    btnText: btn.btnText,
                    color: btn.color,
                    x,
                    bool: btn.bool,
                    fullStateObj,
                    dispatcher,
                    workupDispatcher,
                    inflamDispatcher,
                    close,
                    histPush,
                  }}
                />
              ))}
            {x.name !== "CSF" && (
              <>
                <ModalBtn
                  btnProps={{
                    fluidModalResultClick,
                    btnText: "Negative",
                    color: "green",
                    x,
                    bool: false,
                    fullStateObj,
                    dispatcher,
                    workupDispatcher,
                    inflamDispatcher,
                    close,
                    histPush,
                  }}
                />
                <ModalBtn
                  btnProps={{
                    fluidModalResultClick,
                    btnText: "Positive",
                    color: "red",
                    x,
                    bool: true,
                    fullStateObj,
                    dispatcher,
                    workupDispatcher,
                    inflamDispatcher,
                    close,
                    histPush,
                  }}
                />
              </>
            )}

            {x.name === "CSF" && fluidWorkup["CSF"] !== YLP && (
              <>
                <Modal.Content
                  style={{
                    marginBottom: "0.4em",
                    fontStyle: "italic",
                    textAlign: "left",
                    color: "red",
                  }}>
                  <p>
                    {x.name === "CSF"
                      ? "If you choose to Defer LP in this age group, it is also recommended you then WITHOLD antibiotics"
                      : null}
                  </p>
                </Modal.Content>
                <ModalBtn
                  btnProps={{
                    fluidModalResultClick,
                    btnText: `Defer ${x.name}`,
                    // x.name === "Blood" ? "Defer Bloodwork" : "Defer LP",
                    color: "yellow",
                    x,
                    bool: `deferred`,
                    fullStateObj,
                    dispatcher,
                    workupDispatcher,
                    inflamDispatcher,
                    close,
                    histPush,
                  }}
                />
              </>
            )}
            {x.name !== "CSF" &&
              eqOne(fluidWorkup[x.name], [M, NN, "Consider"]) && (
                <ModalBtn
                  btnProps={{
                    fluidModalResultClick,
                    btnText: `Defer ${x.name}`,
                    color: "yellow",
                    x,
                    bool: `deferred`,
                    fullStateObj,
                    dispatcher,
                    workupDispatcher,
                    inflamDispatcher,
                    close,
                    histPush,
                  }}
                />
              )}
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
};

export default JpModal;
