import a7to21_Fluid_SLo from "./fluidSLos/a7to21_Fluid_SLo";
import a22to28_Fluid_SLo from "./fluidSLos/a22to28_Fluid_SLo";
import a29to60_Fluid_SLo from "./fluidSLos/a29to60_Fluid_SLo";
import a61to90_Fluid_SLo from "./fluidSLos/a61to90_Fluid_SLo";
const fluidModalLogic = (
  x,
  bool,
  fullStateObj,
  dispatcher,
  workupDispatcher,
  inflamDispatcher,
  close,
  histPush
) => {
  const {
    core: {
      coreBase: { age, feverInflam, congenRisk, focalDx, ill, view },
    },
    derFever: {
      derFeverBase: { fluidWorkup, fluidInflamm },
    },
  } = fullStateObj;

  age === "7-21 days" &&
    a7to21_Fluid_SLo(
      x,
      bool,
      fullStateObj,
      dispatcher,
      workupDispatcher,
      inflamDispatcher,
      close,
      histPush
    );

  age === "22-28 days" &&
    a22to28_Fluid_SLo(
      x,
      bool,
      fullStateObj,
      dispatcher,
      workupDispatcher,
      inflamDispatcher,
      close,
      histPush
    );

  age === "29-60 days" &&
    a29to60_Fluid_SLo(
      x,
      bool,
      fullStateObj,
      dispatcher,
      workupDispatcher,
      inflamDispatcher,
      close,
      histPush
    );

  age === "61-90 days" &&
    a61to90_Fluid_SLo(
      x,
      bool,
      fullStateObj,
      dispatcher,
      workupDispatcher,
      inflamDispatcher,
      close,
      histPush
    );
};

export default fluidModalLogic;
