import acronyms from "../../utils/acronyms";
import { eqOne } from "../../fxns/operatorFxns";
const a29to60_Inputs_SLo_Bro = (fullStateObj, dispatcher) => {
  const {
    core: {
      coreBase: {
        age,
        feverInflam,
        congenRisk,
        focalDx,
        ill,
        imm,
        penias,
        hypotherm,
        hsvRisk,
        view,
      },
    },
    derFever: {
      derFeverBase: {
        fluidWorkup: { Urine: uWU, Blood: bWU, CSF: cWU },
        fluidInflamm: { Urine: uI, Blood: bI, CSF: cI, loInflam },
        mngRecs: { Therapy, Dispo, Course },
      },
    },
  } = fullStateObj;

  const {
    Y,
    YLP,
    MLP,
    NNLP,
    NN,
    PLP,
    DEP,
    illa,
    bro,
    mayNoAbx,
    M,
    abx,
    mayAbx,
    nnAbx,
    min24,
    adm,
    dLP,
    trau,
    unsuc,
    cov,
    discWiPeds,
  } = acronyms;

  const bro_29to60_Inputs_dpr = (u, b, c) => {
    return dispatcher("fluidWorkup", {
      Urine: u,
      Blood: b,
      CSF: c,
    });
  };

  const bro_logicizer = () => {
    const arrIncluCat1to6 =
      focalDx.some((f) =>
        [
          "Focal Bacterial infection",
          "Skin or Oral vesicles",
          "Seizures",
        ].includes(f)
      ) ||
      congenRisk.some((c) => [
        "<37 wk EGA at birth",
        "Maternal Chorio or Fever within 48 hours of birth",
        "Neonatal course complicated by surgery or infection",
        "Maternal history genital HSV lesions",
        "Immunocompromised",
        "Congenital or Chromosomal abnormality",
        "Medically fragile",
      ]);

    const hsvRisker =
      penias ||
      hypotherm ||
      congenRisk.includes("Maternal history genital HSV lesions") ||
      focalDx.some((f) => ["Skin or Oral vesicles", "Seizures"].includes(f));

    hsvRisker
      ? bro_29to60_Inputs_dpr(Y, Y, YLP)
      : arrIncluCat1to6
      ? bro_29to60_Inputs_dpr(Y, Y, discWiPeds)
      : imm
      ? bro_29to60_Inputs_dpr("Do NOT", "Do NOT", "Do NOT")
      : focalDx.includes(cov)
      ? bro_29to60_Inputs_dpr(Y, Y, NNLP)
      : uI === null
      ? bro_29to60_Inputs_dpr(Y, DEP, NNLP)
      : uI === true
      ? bro_29to60_Inputs_dpr(Y, M, NNLP)
      : uI === false
      ? bro_29to60_Inputs_dpr(Y, NN, NNLP)
      : console.log("No bro_29to60_Inputs_dpr fired");
  };
  bro_logicizer();
};

export default a29to60_Inputs_SLo_Bro;
