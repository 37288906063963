import React from "react";
// import history from "../../history";
import { Message } from "semantic-ui-react";
import { useSelector } from "react-redux";

const PgHeaderView = () => {
  const { view } = useSelector((state) => state.core.coreBase);
  const msgObj = {
    Start: {
      icon: "thermometer",
      color: "blue",
      header:
        "This App will guide you through the Recs for Febrile Infants in the 1st year of life",
      content: null,
    },
    Age: {
      icon: "male",
      color: "violet",
      header: "AGE of infant:",
      content: null,
    },
    FeverInflam: {
      icon: "thermometer",
      color: "red",
      header:
        "Infants in this age group (<29 days) have higher risk if their fever is >38.5 C.  Indicate below if the Temp is >38.5:",
      content: null,
    },
    Congen: {
      icon: "warning",
      color: "orange",
      header: "Select any of the following Underlying Risks for this infant:",
      content: null,
    },
    Focal: {
      icon: "crosshairs",
      color: "yellow",
      header: "Select any of the following if Evident Infection:",
      content: null,
    },
    Ill: {
      icon: "frown",
      color: "red",
      header:
        "Infants who are truly 'Ill-Appearing' will require urgent full workup and empiric antibiotics, without delay, unless they have bronchiolitis",
      content: null,
    },
    Exclusions: {
      icon: "warning",
      color: "red",
      header:
        "In this age group, with risks you've entered -> the Focal infections entered exclude this infant from this algorithm.  Peds should be consulted before proceeding",
      content: null,
    },
    Imm: {
      icon: "syringe",
      color: "green",
      header:
        "Please indicate whether the infant has received any vaccinations in the last 48 hours:",
      content: null,
    },
  };

  return (
    <>
      {/* {age === "7-21 days" && (congenRisk || focalDx) ? (
        <Message
          icon={msgObj["Exclusions"].icon}
          header={msgObj["Exclusions"].header}
          content={msgObj["Exclusions"].content}
          size="mini"
          floating
          color={msgObj["Exclusions"].color}
        />
      ) : ( */}
      <Message
        icon={msgObj[view].icon}
        header={msgObj[view].header}
        content={msgObj[view].content}
        size="mini"
        floating
        color={msgObj[view].color}
        style={{ textAlign: "left" }}
      />
      {/* )} */}

      {/* {view === "FeverInflam" && (
        <Message
          icon={msgObj[view].icon}
          header="Hypothermia can also be a risk, especially for HSV - please indicate if this infant presented with Temp <36.0C"
          size="mini"
          floating
          color="orange"
          style={{ textAlign: "left" }}
        />
      )} */}
    </>
  );
};

export default PgHeaderView;

/* <Header as="h1">{headerObj[view]}</Header>; */
