import React from "react";
import { Message } from "semantic-ui-react";
import InputsBtn from "../elements/InputsBtn";

const GmStainView = () => {
  return (
    <div>
      <Message
        icon="bug"
        header="CSF Pleocytosis with a negative gram stain increases suspicion for HSV.  Indicate below the results of the Gram Stain"
        // content
        size="mini"
        floating
        color="purple"
        style={{ textAlign: "left", marginTop: "1em" }}
      />
      <InputsBtn
        btnProps={{
          field: "hsvRisk",
          val: false,
          content: "Positive Gram Stain",
          nextView: "MgmtStart",
          color: "purple",
          // icon: null,
          histoPush: "/mgmtLand",
        }}
      />
      <InputsBtn
        btnProps={{
          field: "hsvRisk",
          val: true,
          content: "Negative Gram Stain",
          nextView: "MgmtStart",
          color: "purple",
          // icon: null,
          histoPush: "/mgmtLand",
        }}
      />
    </div>
  );
};

export default GmStainView;
