import React, { useState, useEffect } from "react";
// import history from "../../history";
import InputsBtn from "../elements/InputsBtn";
import { useSelector } from "react-redux";
import { eqOne } from "../../fxns/operatorFxns";
import { List } from "semantic-ui-react";

const IllView = () => {
  const { age, congenRisk, focalDx, ill } = useSelector(
    (state) => state.core.coreBase
  );
  const [illArr, setIllArr] = useState([]);
  const illOpts = [
    "Altered mental status for age",
    "Poor perfusion is evident",
    "There are signs of sepsis",
    "The infant has hypotension",
    "There are signs of significant respiratory distress WITHOUT evidence of Bronchiolitis",
    "None of above but just looks poor, even with temp down",
  ];

  const ageCheckr = (passedAge) => {
    return passedAge === "7-21 days" || passedAge === "22-28 days"
      ? "Congen"
      : passedAge === "<7 days"
      ? "LT7Start"
      : passedAge === "91-365 days"
      ? "OldStart"
      : "Imm";
  };

  const viewer = (passedAge) => {
    return passedAge === "91-365 days" ? "OldStart" : "RecsStart";
  };

  const ckPush = (passedAge) => {
    return passedAge === "91-365 days" ? "/oldLand" : "/recsLand";
  };

  const addToIllArr = (newIll) => {
    setIllArr([...illArr, newIll]);
  };

  return (
    <div>
      <h4 style={{ color: "gray", marginBottom: "0" }}>
        Indicate if any of below are true:
      </h4>
      {/* <InputsBtn
        btnProps={{
          field: "ill",
          val: "Ill-appearing",
          content: "YES the infant is 'Ill-appearing'",
          nextView: "RecsStart",
          icon: "white frown left large",
          labelPosition: "left",
          color: "red",
          histoPush: "/recsLand",
        }}
      /> */}
      <List>
        {illOpts.map(
          (x, i) =>
            !illArr.includes(x) && (
              <List.Item
                key={i}
                style={{
                  margin: "0em",
                  fontSize: "1.0em",
                  lineHeight: "1.2em",
                }}>
                <InputsBtn
                  btnProps={{
                    field: "ill",
                    val: "Ill-appearing",
                    content: x,
                    // nextView: "Focal",
                    color: "red",
                    // histoPush: "/exclu",
                  }}
                  addToArr={() => addToIllArr(x)}
                />
              </List.Item>
            )
        )}
      </List>
      <InputsBtn
        btnProps={{
          field: "ill",
          val: null,
          content: "All signs of Ill State from above have been entered",
          nextView: viewer(age),
          color: "orange",
          icon: "arrow right large",
          labelPosition: "right",
          histoPush: ckPush(age),
        }}
      />
      <InputsBtn
        btnProps={{
          field: "ill",
          val: "NOT Ill-appearing",
          content: "NO the infant does not have any of above signs",
          nextView: ageCheckr(age),
          color: "green",
          icon: "arrow right large",
          labelPosition: "right",
          // histoPush: histoPushDecider(),
        }}
      />
    </div>
  );
};

export default IllView;
