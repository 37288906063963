import acronyms from "../../utils/acronyms";
import a22to28_Inputs_SLo_Bro from "./a22-28_Inputs_SLo_Bro";
const a22to28_Inputs_SLo = (fullStateObj, dispatcher) => {
  const {
    core: {
      coreBase: {
        age,
        feverInflam,
        congenRisk,
        focalDx,
        ill,
        penias,
        hypotherm,
        hsvRisk,
        view,
      },
    },
    derFever: {
      derFeverBase: {
        fluidWorkup: { Urine: uWU, Blood: bWU, CSF: cWU },
        fluidInflamm: { Urine: uI, Blood: bI, CSF: cI, loInflam },
        mngRecs: { Therapy, Dispo, Course },
      },
    },
  } = fullStateObj;

  const {
    Y,
    YLP,
    MLP,
    NNLP,
    NN,
    PLP,
    DEP,
    illa,
    bro,
    cov,
    vesicles,
    mayNoAbx,
    abx,
    mayAbx,
    nnAbx,
    min24,
    adm,
    dLP,
    trau,
    unsuc,
  } = acronyms;

  const dispatchinator = (CSFpass) => {
    return dispatcher("fluidWorkup", {
      Urine: Y,
      Blood: Y,
      CSF: CSFpass,
    });
  };

  const logicizer = () => {
    ill === illa || focalDx.includes("Seizures") || focalDx.includes(vesicles)
      ? dispatchinator(YLP)
      : focalDx.includes(bro) || focalDx.includes("COVID positive")
      ? a22to28_Inputs_SLo_Bro(fullStateObj, dispatcher)
      : feverInflam === true ||
        loInflam === false ||
        congenRisk.length > 0 ||
        focalDx.includes(vesicles)
      ? dispatchinator(YLP)
      : loInflam !== false && uI !== null && bI !== null && cI === null
      ? dispatchinator(NNLP)
      : feverInflam !== true
      ? dispatchinator(DEP)
      : ill !== illa && feverInflam !== true
      ? dispatchinator(NNLP)
      : // this will Default fire if no prior Return:
        console.log("No logicizer fire in a22to28_Inputs_SLo");
  };
  logicizer();
};

export default a22to28_Inputs_SLo;
