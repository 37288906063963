import React, { useEffect } from "react";
import { Container } from "semantic-ui-react";
import { useSelector } from "react-redux";
import history from "../../history";
import LabelView from "../views/LabelView";
import LandView from "../views/LandView";
import RecsHeaderView from "../views/Headers/RecsHeaderView";
import RecsStartView from "../views/RecsStartView";
import GmStainView from "../views/GmStainView";
import HsvAssessView from "../views/HsvAssessView";

const RecsLandPg = () => {
  const { view } = useSelector((state) => state.core.coreBase);
  const histPush = (passed) => {
    history.push({
      pathname: passed,
      // state: { v },
    });
  };
  useEffect(() => {
    view === "Start" && histPush("/");
  });

  return (
    <Container text style={{ marginTop: "5em", textAlign: "center" }}>
      <LabelView />
      {/* <RecsHeaderView /> */}
      {view === "RecsStart" && <RecsHeaderView />}
      {view === "RecsStart" && <RecsStartView />}
      {/* {view === "GmStain" && <GmStainHeaderView />} */}
      {view === "GmStain" && <GmStainView />}
      {view === "HsvAssess" && <HsvAssessView />}
    </Container>
  );
};

export default RecsLandPg;
