import a7to21_Inputs_SLo from "./inputSubLogics/a7to21_Inputs_SLo";
import a22to28_Inputs_SLo from "./inputSubLogics/a22-28_Inputs_SLo";
import a29to60_Inputs_SLo from "./inputSubLogics/a29-60_Inputs_SLo";
import a61to90_Inputs_SLo from "./inputSubLogics/a61-90_Inputs_SLo";
const feverInputLogic = (fullStateObj, dispatcher) => {
  const {
    core: {
      coreBase: { age, feverInflam, congenRisk, focalDx, ill, view },
    },
    derFever: {
      derFeverBase: { fluidWorkup },
    },
  } = fullStateObj;

  age === "7-21 days" && a7to21_Inputs_SLo(fullStateObj, dispatcher);
  age === "22-28 days" && a22to28_Inputs_SLo(fullStateObj, dispatcher);
  age === "29-60 days" && a29to60_Inputs_SLo(fullStateObj, dispatcher);
  age === "61-90 days" && a61to90_Inputs_SLo(fullStateObj, dispatcher);
};

export default feverInputLogic;
