export const CHANGE_CORE_STATE = "change_core_state";
export const SET_HYPO = "set_hypo";
export const RESET_CORE_STATE = "reset_core_state";
export const CHANGE_ARR_STATE = "change_arr_state";

export const CHANGE_DERIVED_FEVER_STATE = "change_derived_fever_state";
export const RESET_DERIVED_FEVER_STATE = "reset_derived_fever_state";

export const CHANGE_INFLAM_STATE = "change_inflam_state";
export const CHANGE_WORKUP_STATE = "change_workup_state";
