import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

// import { persistStore } from "redux-persist";
// import { PersistGate } from "redux-persist/integration/react";

import reduxThunk from "redux-thunk";
import App from "./App";
import reducers from "./1reducers";
import * as actionCreators from "./1actions/index";

const composeEnhancers = composeWithDevTools({
  actionCreators,
  trace: true,
  traceLimit: 25,
});

const store = createStore(
  reducers,
  {},
  composeEnhancers(applyMiddleware(reduxThunk))
);

// const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      {/* <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate> */}
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
