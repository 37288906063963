/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import history from "../../history";
import { Icon, List, Modal, Header, Divider, Button } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import {
  changeInflamState,
  changeWorkupState,
} from "../../1actions/der_fever_actions";
import { changeState } from "../../1actions/core_actions";
import acronyms from "../../utils/acronyms";

const MngView = () => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const {
    core: {
      coreBase: { age, congeRisk, focalDx, ill, view },
    },
    derFever: {
      derFeverBase: {
        fluidWorkup,
        fluidInflamm: { Urine, Blood, CSF, loInflam },
        mngRecs: { Therapy, Dispo, Course },
      },
    },
  } = useSelector((state) => state);

  const {
    Y,
    YLP,
    MLP,
    NNLP,
    NN,
    PLP,
    DEP,
    illa,
    bro,
    cov,
    vesicles,
    matLesions,
    M,
    mayNoAbx,
    abx,
    abxPleo,
    abxHsvRiskCongen,
    abxAcyclo,
    hsvStudies,
    mayAbx,
    nnAbx,
    min24,
    adm,
    dLP,
    D,
    trau,
    unsuc,
    hospOrHome,
    abxParOrOral,
    oralAbx,
    obsHome,
    obsHomeUnlessResp,
    fu12to24,
    fu24to36,
    discWiPeds,
  } = acronyms;

  const mngListItems = [
    {
      name: "Therapy",
      icon: "pills",
      color: "red",
      descrip: Therapy,
    },
    {
      name: "Disposition",
      icon: "sign out alternate",
      color: "yellow",
      descrip: Dispo,
    },
    {
      name: "Course",
      icon: "calendar alternate",
      color: "purple",
      descrip: Course,
    },
  ];

  return (
    <div>
      <List style={{ textAlign: "left", verticalAlign: "middle" }}>
        {mngListItems.map((x, i) => (
          <>
            <List.Item
              key={i}
              style={{ margin: "0.6em 0 0 0.6em", fontSize: "1.0em" }}>
              <Icon name={x.icon} color={x.color} size="large" />
              <List.Content>
                <List.Header
                  style={{
                    color: "steelblue",
                    marginBottom: "0.4em",
                  }}>{`${x.name}: `}</List.Header>
                <List.Description
                  style={{
                    fontSize: "1.2em",
                    marginLeft: "0.3em",
                    lineHeight: "1.2em",
                  }}>
                  {x.descrip}
                </List.Description>
              </List.Content>
            </List.Item>
            {x.name === "Disposition" &&
              [obsHomeUnlessResp, obsHome, hospOrHome].some(
                (x) => x === Dispo
              ) && (
                <Modal
                  dimmer="blurring"
                  open={open}
                  trigger={
                    <Button
                      color="blue"
                      style={{
                        opacity: "0.8",
                        marginLeft: "2.4em",
                        padding: "0.6em 0.6em",
                      }}
                      size="small">
                      Review Safe Home Management &nbsp; &gt;
                    </Button>
                  }
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}>
                  <Modal.Content>
                    <Header
                      icon="home"
                      content="Clinicians may manage infants at Home if ALL of the following criteria are met:"
                      style={{
                        color: "steelblue",
                        textAlign: "center",
                        fontSize: "1.4em",
                      }}
                    />
                    <Divider />
                    <List
                      style={{
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}>
                      <List.Item
                        style={{
                          margin: "0.6em 0 1em 0em",
                          fontSize: "1.0em",
                        }}>
                        <Icon name="numbered list" color="green" size="large" />
                        <List.Content>
                          <List.Header
                            style={{
                              fontSize: "1.2em",
                              marginLeft: "0.3em",
                              lineHeight: "1.2em",
                            }}>
                            Verbal teaching and written instructions have been
                            provided for monitoring throughout the period at
                            home
                          </List.Header>
                        </List.Content>
                      </List.Item>

                      <List.Item
                        style={{
                          margin: "0.6em 0 1em 0em",
                          fontSize: "1.0em",
                        }}>
                        <Icon name="repeat" color="purple" size="large" />
                        <List.Content>
                          <List.Header
                            style={{
                              fontSize: "1.2em",
                              marginLeft: "0.3em",
                              lineHeight: "1.2em",
                            }}>
                            Follow-up plans for re-evaluation have been
                            developed and are in place
                          </List.Header>
                        </List.Content>
                      </List.Item>

                      <List.Item
                        style={{
                          margin: "0.6em 0 1em 0em",
                          fontSize: "1.0em",
                        }}>
                        <Icon name="emergency" color="red" size="large" />
                        <List.Content>
                          <List.Header
                            style={{
                              fontSize: "1.2em",
                              marginLeft: "0.3em",
                              lineHeight: "1.2em",
                            }}>
                            Plans have been developed and are in place in case
                            of change in clinical status, including means of
                            communication between family and providers and
                            access to emergency medical care
                          </List.Header>
                        </List.Content>
                      </List.Item>
                    </List>
                    <Divider />
                    <Modal.Actions
                      style={{ textAlign: "center", margin: "0em" }}>
                      <Button
                        content="Understood"
                        labelPosition="right"
                        icon="checkmark"
                        onClick={() => setOpen(false)}
                        color="blue"
                      />
                    </Modal.Actions>
                  </Modal.Content>
                </Modal>
              )}
          </>
        ))}
      </List>
    </div>
  );
};

export default MngView;
