import a7to21_Mng_SLo from "./mngSubLogics/a7to21_Mng_SLo";
import a22to28_Mng_SLo from "./mngSubLogics/a22-28_Mng_SLo";
import a29to60_Mng_SLo from "./mngSubLogics/a29to60_Mng_SLo";
import a61to90_Mng_SLo from "./mngSubLogics/a61-90_Mng_SLo";
import acronyms from "../utils/acronyms";

const mngLogic = (fullStateObj, dispatcher) => {
  const {
    core: {
      coreBase: {
        age,
        feverInflam,
        congenRisk,
        focalDx,
        ill,
        penias,
        hypotherm,
        hsvRisk,
        view,
      },
    },
    derFever: {
      derFeverBase: {
        fluidWorkup: { Urine: uWU, Blood: bWU, CSF: cWU },
        fluidInflamm: { Urine: uI, Blood: bI, CSF: cI, loInflam },
        mngRecs: { Therapy, Dispo, Course },
      },
    },
  } = fullStateObj;

  const {
    Y,
    YLP,
    MLP,
    NNLP,
    PLP,
    DEP,
    illa,
    bro,
    mayNoAbx,
    abx,
    mayAbx,
    nnAbx,
    min24,
    adm,
    dLP,
    trau,
    unsuc,
  } = acronyms;

  age === "7-21 days" && a7to21_Mng_SLo(fullStateObj, dispatcher);
  age === "22-28 days" && a22to28_Mng_SLo(fullStateObj, dispatcher);
  age === "29-60 days" && a29to60_Mng_SLo(fullStateObj, dispatcher);
  age === "61-90 days" && a61to90_Mng_SLo(fullStateObj, dispatcher);
};

export default mngLogic;
