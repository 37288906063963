import acronyms from "../../utils/acronyms";
import a7to21_Mng_SLo_Bro from "./a7to21_Mng_SLo_Bro";
const a7to21_Mng_SLo = (fullStateObj, dispatcher) => {
  const {
    core: {
      coreBase: {
        age,
        feverInflam,
        congenRisk,
        focalDx,
        ill,
        penias,
        hypotherm,
        hsvRisk,
        view,
      },
    },
    derFever: {
      derFeverBase: {
        fluidWorkup: { Urine: uWU, Blood: bWU, CSF: cWU },
        fluidInflamm: { Urine: uI, Blood: bI, CSF: cI, loInflam },
        mngRecs: { Therapy, Dispo, Course },
      },
    },
  } = fullStateObj;

  const {
    Y,
    YLP,
    MLP,
    NNLP,
    PLP,
    DEP,
    illa,
    bro,
    mayNoAbx,
    abx,
    abxPleo,
    abxAcyclo,
    hsvStudies,
    abxHsvRiskCongen,
    mayAbx,
    nnAbx,
    min24,
    min24Acyclo,
    posGmStain,
    adm,
    dLP,
    trau,
    unsuc,
  } = acronyms;

  const dispatchinator = (rx, disp, cou) => {
    return dispatcher("mngRecs", {
      Therapy: rx,
      Dispo: disp,
      Course: cou,
    });
  };

  const logicizer = () => {
    if (cI === "posGmStain") {
      dispatchinator(abx, adm, posGmStain);
      return;
    }
    if (hsvRisk || penias) {
      dispatchinator(abxAcyclo, adm, min24Acyclo);
      return;
    }
    if (ill === illa || (uWU === Y && bWU === Y && cWU === YLP)) {
      dispatchinator(abx, adm, min24);
      return;
    }
    if (focalDx.includes(bro) || focalDx.includes("COVID positive")) {
      a7to21_Mng_SLo_Bro(fullStateObj, dispatcher);
      return;
    }

    // ! zz YES Abx :
    if (congenRisk.length > 0 || cI === true || bI === true) {
      hsvRisk || hypotherm || cI === true || penias
        ? dispatchinator(abxAcyclo, adm, min24)
        : dispatchinator(abx, adm, min24);
      return;
    }
    if (
      (loInflam === false || loInflam === null || feverInflam) &&
      (cI === trau || cI === unsuc)
    ) {
      dispatchinator(abx, adm, min24);
      return;
    }
    if (cI === true) {
      dispatchinator(abxPleo, adm, min24);
      return;
    }

    // MAY start Abx :
    if (false) {
      dispatchinator(mayAbx, adm, min24);
      return;
    }

    //  ! zz MAY WITHOLD Abx :
    if (
      ((loInflam || loInflam === null) && !feverInflam) ||
      ((feverInflam || !loInflam) && !uI && !bI && (!cI || cI === null))
    ) {
      dispatchinator(mayNoAbx, adm, min24);
      return;
    }

    // NEED NOT Abx :
    if (false) {
      dispatchinator(nnAbx, adm, min24);
      return;
    }
    console.log("a7to21_Mng_SLo no fire logicizer");
  };
  logicizer();
};

export default a7to21_Mng_SLo;
