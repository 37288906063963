import acronyms from "../../utils/acronyms";
const a61to90_Inputs_SLo = (fullStateObj, dispatcher) => {
  const {
    core: {
      coreBase: {
        age,
        feverInflam,
        congenRisk,
        focalDx,
        ill,
        imm,
        penias,
        hypotherm,
        hsvRisk,
        view,
      },
    },
    derFever: {
      derFeverBase: {
        fluidWorkup: { Urine: uWU, Blood: bWU, CSF: cWU },
        fluidInflamm: { Urine: uI, Blood: bI, CSF: cI, loInflam },
        mngRecs: { Therapy, Dispo, Course },
      },
    },
  } = fullStateObj;

  const {
    Y,
    YLP,
    MLP,
    NNLP,
    NN,
    DEP,
    illa,
    bro,
    cov,
    vesicles,
    mayNoAbx,
    abx,
    mayAbx,
    nnAbx,
    min24,
    adm,
    dLP,
    trau,
    unsuc,
    discWiPeds,
    M,
  } = acronyms;

  const dispatchinator = (u, b, c) => {
    return dispatcher("fluidWorkup", {
      Urine: u,
      Blood: b,
      CSF: c,
    });
  };

  const arrIncluCat1to6 =
    focalDx.some((f) => ["Focal Bacterial infection"].includes(f)) ||
    congenRisk.some((c) => [
      "<37 wk EGA at birth",
      "Maternal Chorio or Fever within 48 hours of birth",
      "Neonatal course complicated by surgery or infection",
      "Maternal history genital HSV lesions",
      "Immunocompromised",
      "Congenital or Chromosomal abnormality",
      "Medically fragile",
    ]);

  const logicizer = () => {
    ill === illa ||
    focalDx.includes("Seizures") ||
    focalDx.includes("Maternal history genital HSV lesions")
      ? dispatchinator(Y, Y, YLP)
      : /* (focalDx.includes(cov) && arrIncluCat1to6) || */ imm &&
        arrIncluCat1to6
      ? dispatchinator(discWiPeds, discWiPeds, discWiPeds)
      : arrIncluCat1to6
      ? dispatchinator(Y, Y, MLP)
      : imm || focalDx.includes(bro) || focalDx.includes(cov)
      ? dispatchinator(NN, NN, NNLP)
      : dispatchinator(M, DEP, NNLP);
  };
  logicizer();
};

export default a61to90_Inputs_SLo;
