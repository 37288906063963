import { CHANGE_CORE_STATE } from "./types";
import { SET_HYPO } from "./types";
import { RESET_CORE_STATE } from "./types";
import { RESET_DERIVED_FEVER_STATE } from "./types";
// import { CHANGE_INFLAM_STATE } from "./types";
import { CHANGE_ARR_STATE } from "./types";

export const changeState = (f, v, nextView) => async (dispatch) => {
  try {
    if (f === "focalDx" || f === "congenRisk") {
      dispatch({
        type: CHANGE_ARR_STATE,
        payload: { field: f, value: [v] },
      });
      if (
        v === "Maternal history genital HSV lesions" ||
        v === "Maternal Chorio or Fever within 48 hours of birth" ||
        v === "Skin or Oral vesicles" ||
        v === "Seizures" ||
        v === "Hypothermia: episodes of Temp <36.5C"
      ) {
        dispatch({
          type: CHANGE_CORE_STATE,
          payload: { field: "hsvRisk", value: true },
        });
      }
      v === "Hypothermia: episodes of Temp <36.5C" &&
        dispatch({
          type: SET_HYPO,
          payload: { field: "hypotherm", value: true },
        });
    } else {
      v &&
        dispatch({
          type: CHANGE_CORE_STATE,
          payload: { field: f, value: v },
        });
    }
    nextView &&
      dispatch({
        type: CHANGE_CORE_STATE,
        payload: { field: "view", value: nextView },
      });
  } catch (err) {
    console.log("Something went wrong with changeState -> changeStateAC");
  }
};

export const changeViewOnly = (nextView) => async (dispatch) => {
  try {
    dispatch({
      type: CHANGE_CORE_STATE,
      payload: { field: "view", value: nextView },
    });
  } catch (err) {
    console.log("Something went wrong with changeViewOnlyAC");
  }
};

export const setHypo = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_HYPO,
      payload: { field: "hypotherm", value: true },
    });
    /* dispatch({
      type: CHANGE_CORE_STATE,
      payload: { field: "hsvRisk", value: true },
    }); */
    dispatch({
      type: CHANGE_CORE_STATE,
      payload: { field: "view", value: "Congen" },
    });
  } catch (err) {
    console.log("Something went wrong with set_hypo");
  }
};

export const resetCoreState = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_CORE_STATE,
    });
    dispatch({
      type: RESET_DERIVED_FEVER_STATE,
    });
  } catch (err) {
    console.log("Something went wrong with resetState -> resetStateAC");
  }
};
