import React from "react";
import {
  Container,
  Divider,
  Grid,
  Header,
  Image,
  List,
  Segment,
} from "semantic-ui-react";
import logo from "../../assets/AlgoLogo_Ocho.png";

const FooterCompo = () => {
  return (
    <Segment
      vertical
      style={{ margin: "0em 0em 0em", padding: "0em 0em 1em 0em" }}>
      <Container textAlign="center">
        <Divider section />
        <Grid divided>
          <Grid.Column>
            <Header as="h4" content="Infant Fever Algo" />
            <p>The algorithms used are those endorsed by the CA First Group.</p>
          </Grid.Column>
        </Grid>
        {/* <Divider section /> */}
        <Image centered size="mini" src={logo} style={{ marginTop: "1em" }} />
        {/* <List horizontal divided link size="small">
          <List.Item as="a" href="#">
            Contact Us
          </List.Item>
          <List.Item as="a" href="#">
            Terms and Conditions
          </List.Item>
          <List.Item as="a" href="#">
            Privacy Policy
          </List.Item>
        </List> */}
      </Container>
    </Segment>
  );
};

export default FooterCompo;
