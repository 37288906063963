import acronyms from "../../utils/acronyms";
import { eqOne, allTrue } from "../../fxns/operatorFxns";

const a61to90_Fluid_SLo = (
  x,
  bool,
  fullStateObj,
  dispatcher,
  workupDispatcher,
  inflamDispatcher,
  close,
  histPush
) => {
  const {
    core: {
      coreBase: { age, feverInflam, congenRisk, focalDx, ill, hsvRisk, view },
    },
    derFever: {
      derFeverBase: {
        fluidWorkup: { CSF: cWU },
        fluidInflamm,
      },
    },
  } = fullStateObj;
  const {
    Y,
    YLP,
    MLP,
    NNLP,
    NN,
    PLP,
    DEP,
    illa,
    bro,
    mayNoAbx,
    abx,
    mayAbx,
    nnAbx,
    min24,
    adm,
    dLP,
    C,
    D,
    trau,
    unsuc,
  } = acronyms;

  const urineLogic = () => {
    inflamDispatcher("Urine", bool);
    if (ill !== illa) {
      bool === true && workupDispatcher("Blood", C);
      eqOne(bool, [false, "deferred"]) && workupDispatcher("Blood", NN);
    }
    histPusher();
  };

  const bloodLogic = () => {
    inflamDispatcher("Blood", bool);
    bool === true && workupDispatcher("Urine", C);
    histPusher();
  };

  const csfLogic = () => {
    inflamDispatcher("CSF", bool);
    bool === true && workupDispatcher("Blood", Y);
    histPusher();
  };

  const histPusher = () => {
    if (
      fluidInflamm["Urine"] !== null &&
      fluidInflamm["Blood"] !== null &&
      fluidInflamm["CSF"] !== null
    ) {
      dispatcher("view", "MgmtStart");
      histPush();
    }
  };

  const logicSwitcher = () => {
    return (
      (x.name === "Urine" && urineLogic()) ||
      (x.name === "Blood" && bloodLogic()) ||
      (x.name === "CSF" && csfLogic())
    );
  };
  logicSwitcher();
};

export default a61to90_Fluid_SLo;
