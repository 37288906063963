import acronyms from "../../utils/acronyms";
const a7to21_Mng_SLo_Bro = (fullStateObj, dispatcher) => {
  const {
    core: {
      coreBase: {
        age,
        feverInflam,
        congenRisk,
        focalDx,
        ill,
        penias,
        hypotherm,
        hsvRisk,
        view,
      },
    },
    derFever: {
      derFeverBase: {
        fluidWorkup: { Urine: uWU, Blood: bWU, CSF: cWU },
        fluidInflamm: { Urine: uI, Blood: bI, CSF: cI, loInflam },
        mngRecs: { Therapy, Dispo, Course },
      },
    },
  } = fullStateObj;

  const {
    Y,
    YLP,
    MLP,
    NNLP,
    PLP,
    DEP,
    illa,
    bro,
    mayNoAbx,
    abx,
    abxPleo,
    mayAbx,
    nnAbx,
    min24,
    adm,
    dLP,
    trau,
    unsuc,
  } = acronyms;

  const bro_dispatchinator = (rx, disp, cou) => {
    // console.log("MNG bro_dipatchinator fired: ", rx, disp, cou);
    return dispatcher("mngRecs", {
      Therapy: rx,
      Dispo: disp,
      Course: cou,
    });
  };

  const bro_logicizer = () => {
    return cI === true
      ? bro_dispatchinator(abxPleo, adm, min24)
      : cI === trau
      ? bro_dispatchinator(abx, adm, min24)
      : uI || bI
      ? bro_dispatchinator(mayAbx, adm, min24)
      : loInflam && cWU === NNLP
      ? bro_dispatchinator(nnAbx, adm, min24)
      : !uI && !bI
      ? bro_dispatchinator(mayNoAbx, adm, min24)
      : console.log("No MngSloBro logicizer option triggered");
  };

  /*     // YES Abx :
    if (cI === true) {
      bro_dispatchinator(abxPleo, adm, min24);
      return;
    }
    if (cI === trau) {
      bro_dispatchinator(abx, adm, min24);
      return;
    }
    // MAY start Abx :
    if (uI || bI) {
      bro_dispatchinator(mayAbx, adm, min24);
      return;
    }
    // MAY WITHOLD Abx :;
    if (!uI && !bI) {
      bro_dispatchinator(mayNoAbx, adm, min24);
      return;
    }
    // NEED NOT Abx :
    if (loInflam && cWU === NNLP) {
      bro_dispatchinator(nnAbx, adm, min24);
      return;
    }
    console.log("No MngSlo logicizer option triggered");
*/
  bro_logicizer();
};

export default a7to21_Mng_SLo_Bro;
