import { CHANGE_CORE_STATE } from "../../1actions/types";
import { RESET_CORE_STATE } from "../../1actions/types";
import { SET_HYPO } from "../../1actions/types";
import { CHANGE_ARR_STATE } from "../../1actions/types";

const initialState = {
  age: null,
  feverInflam: null,
  congenRisk: [],
  focalDx: [],
  ill: null,
  imm: null,
  penias: null,
  hypotherm: null,
  hsvRisk: null,
  view: "Start",
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_CORE_STATE:
      // const field = action.payload.field;
      return { ...state, [action.payload.field]: action.payload.value };
    case CHANGE_ARR_STATE:
      return {
        ...state,
        [action.payload.field]: [
          ...state[action.payload.field],
          ...action.payload.value,
        ],
      };
    case SET_HYPO:
      return { ...state, [action.payload.field]: action.payload.value };
    case RESET_CORE_STATE:
      return initialState;
    default:
      return state;
  }
}
