/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FluidsView from "./FluidsView";
import NoFluidBtnsView from "./NoFluidBtnsView";
import feverInputLogic from "../../logics/feverInputLogic";
import {
  changeDerFeverState,
  changeInflamState,
} from "../../1actions/der_fever_actions";
import { allTrue } from "../../fxns/operatorFxns";
import acronyms from "../../utils/acronyms";

const RecsStartView = () => {
  const dispatch = useDispatch();
  const { age, congeRisk, focalDx, ill, view } = useSelector(
    (state) => state.core.coreBase
  );
  const { Urine, Blood, CSF, loInflam } = useSelector(
    (state) => state.derFever.derFeverBase.fluidInflamm
  );
  const fullStateObj = useSelector((state) => state);

  const {
    Urine: UrWu,
    Blood: BlWu,
    CSF: CsfWu,
  } = useSelector((state) => state.derFever.derFeverBase.fluidWorkup);

  const dispatcher = (field, value) => {
    dispatch(changeDerFeverState(field, value));
  };

  const feverInputLogicFxn = (fullStateObj) => {
    checkInflamNull() && feverInputLogic(fullStateObj, dispatcher);
  };

  const checkInflamNull = () => {
    return Urine === null && Blood === null && CSF === null ? true : false;
  };

  const {
    Y,
    YLP,
    MLP,
    NNLP,
    NN,
    PLP,
    DEP,
    illa,
    bro,
    mayNoAbx,
    M,
    abx,
    mayAbx,
    nnAbx,
    min24,
    adm,
    dLP,
    D,
    trau,
    unsuc,
    discWiPeds,
  } = acronyms;

  const checkNoBtns = () => {
    const wuNull = UrWu && allTrue(null, [Urine, Blood, CSF]);
    return wuNull && UrWu === Y && BlWu === Y && CsfWu === YLP
      ? "full"
      : wuNull &&
        UrWu === discWiPeds &&
        BlWu === discWiPeds &&
        CsfWu === discWiPeds
      ? "discuss"
      : wuNull && UrWu === NN && BlWu === NN && CsfWu === NNLP
      ? "needNot"
      : focalDx.includes("Focal Bacterial infection") && !illa
      ? "focalDx"
      : null;
  };

  useEffect(() => {
    feverInputLogicFxn(fullStateObj);
  }, [Urine, Blood]);

  return checkNoBtns() ? (
    <div>
      <NoFluidBtnsView result={checkNoBtns()} />
    </div>
  ) : (
    <div>
      <FluidsView />
    </div>
  );
};

export default RecsStartView;
