import React from "react";
import { useSelector } from "react-redux";
import { Message } from "semantic-ui-react";
import InputsBtn from "../elements/InputsBtn";

const ExclusionsView = () => {
  const {
    core: {
      coreBase: { age, congenRisk, focalDx, ill, imm, view },
    },
  } = useSelector((state) => state);

  const whicher = () => {
    return age === "<7 days"
      ? "lt7d_exclu"
      : age === "29-60 days" && imm
      ? "immAnd29to60"
      : congenRisk
      ? "focalExclu"
      : null;
  };
  const hdrObj = {
    lt7d_exclu:
      "Infants <7 days of life are excluded from this algorithm.  Peds should be consulted before proceeding",
    immAnd29to60:
      "Infants in this age group who have received immunizations in the last 48 hours have lower risk for infection. Peds should be consulted before proceeding.  In general these infants may go home without any further testing.",
    focalExclu: `In this age group, the risk(s) you've entered ( ${congenRisk.join(
      ", "
    )}, ${focalDx.join(
      ", "
    )} ) exclude this infant from this algorithm.  Peds should be consulted before proceeding`,
  };

  return (
    <div>
      <Message
        icon="warning"
        header={hdrObj[whicher()]}
        // content={}
        size="mini"
        floating
        color="red"
        style={{ textAlign: "left" }}
      />
      <InputsBtn
        btnProps={{
          field: "exclusions",
          val: true,
          content: "Cancel and Consult Peds",
          // nextView: "Ill",
          color: "green",
          // icon: null,
          // histoPush: "/exclu",
        }}
      />
    </div>
  );
};

export default ExclusionsView;
