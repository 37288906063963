import produce from "immer";
import { CHANGE_DERIVED_FEVER_STATE } from "../../1actions/types";
import { RESET_DERIVED_FEVER_STATE } from "../../1actions/types";
import { CHANGE_INFLAM_STATE } from "../../1actions/types";
import { CHANGE_WORKUP_STATE } from "../../1actions/types";

const initialState = {
  fluidWorkup: {
    Urine: null,
    Blood: null,
    CSF: null,
  },
  fluidInflamm: {
    Urine: null,
    Blood: null,
    CSF: null,
    loInflam: null,
  },
  mngRecs: {
    Therapy: null,
    Dispo: null,
    Course: null,
  },
  // inflamRisk: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_DERIVED_FEVER_STATE:
      return { ...state, [action.payload.field]: action.payload.value };
    case RESET_DERIVED_FEVER_STATE:
      return initialState;
    case CHANGE_INFLAM_STATE:
      return produce(state, (draft) => {
        draft.fluidInflamm[action.payload.fluid] = action.payload.value;
      });
    case CHANGE_WORKUP_STATE:
      return produce(state, (draft) => {
        draft.fluidWorkup[action.payload.fluid] = action.payload.value;
      });
    default:
      return state;
  }
}
