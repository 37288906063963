import React from "react";
import { Router, Route } from "react-router-dom";
import history from "./history";
import "semantic-ui-css/semantic.min.css";
import { Container } from "semantic-ui-react";

import MenuCompo from "./components/elements/Menu";
import FooterCompo from "./components/elements/Footer";
// import LandPg from "./components/pages/LandPg";
import FeverLandPg from "./components/pages/FeverLandPg";
import RecsLandPg from "./components/pages/RecsLandPg";
import MgmtLandPg from "./components/pages/MgmtLandPg";
import OldLandPg from "./components/pages/OldLandPg";
import SecondPg from "./components/pages/SecondPg";

function App() {
  return (
    <Container>
      <Router history={history}>
        <div>
          <MenuCompo />
          <Route exact path="/" component={FeverLandPg} />
          <Route exact path="/recsLand" component={RecsLandPg} />
          <Route exact path="/mgmtLand" component={MgmtLandPg} />
          <Route exact path="/oldLand" component={OldLandPg} />
          <Route exact path="/second" component={SecondPg} />
          <FooterCompo />
        </div>
      </Router>
    </Container>
  );
}

export default App;
