import React, { useState, useEffect } from "react";
import { List, Message, Button, Icon } from "semantic-ui-react";

const LT7View = () => {
  const [vueArr, setVueArr] = useState(["land", "recs"]);
  const [vue, setVue] = useState(vueArr[0]);
  const listItems = [
    {
      name: "Urine",
      icon: "flask",
      color: "yellow",
      descrip: "YES obtain (cath) UA with micro and Cx",
    },
    {
      name: "Blood",
      icon: "syringe",
      color: "red",
      descrip: "YES obtain CBC, Blood Cx and 'May' obtain CRP",
    },
    {
      name: "CSF",
      icon: "lightning",
      color: "purple",
      descrip: "YES obtain CSF and Cx",
    },
    {
      name: "Therapy and Disposition",
      icon: "pills",
      color: "blue",
      descrip: "Peds Consult for Hospital admission and Parenteral antibiotics",
    },
  ];

  useEffect(() => {
    setVue(vueArr[0]);
  }, [vueArr]);

  return (
    <div>
      {vue === "land" && (
        <>
          <Message
            size="mini"
            floating
            color="blue"
            style={{ textAlign: "left", marginTop: "1em" }}>
            <h6 style={{ marginBottom: "1em" }}>
              The Early Onset Sepsis Calcluator calculates the neonate's risk of
              sepsis at birth. It is validated for use in the first 24 hours of
              life:
            </h6>
            <a
              href="https://neonatalsepsiscalculator.kaiserpermanente.org/"
              rel="noreferrer"
              style={{
                color: "blue",
                textAlign: "center",
                textDecoration: "underline",
                fontStyle: "italic",
              }}>
              Link to Neonatal Sepsis Calculator &#62;
            </a>
            <h6>
              If the infant is beyond the birth hospitalization then Proceed:
            </h6>
          </Message>
          <Button
            onClick={() => setVueArr(vueArr.slice(1))}
            content="Infant has been discharged from birth hospitalization"
            icon="right arrow"
            labelPosition="right"
            style={{
              color: "white",
              backgroundColor: "steelblue",
              lineHeight: "1.2em",
            }}></Button>
        </>
      )}
      {vue === "recs" && (
        <List style={{ textAlign: "left", verticalAlign: "middle" }}>
          {listItems.map((x, i) => (
            <List.Item
              key={i}
              style={{ margin: "0.6em 0 0 0.6em", fontSize: "1.0em" }}>
              <Icon name={x.icon} color={x.color} size="large" />
              <List.Content>
                <List.Header
                  style={{
                    color: "steelblue",
                    marginBottom: "0.4em",
                  }}>{`${x.name}: `}</List.Header>
                <List.Description
                  style={{
                    fontSize: "1.1em",
                    marginLeft: "0em",
                    lineHeight: "1.1em",
                  }}>
                  {x.descrip}
                  {x.name === "Urine" && (
                    <p
                      style={{
                        color: "steelblue",
                        fontStyle: "italic",
                        opacity: "0.5",
                        fontSize: "0.6em",
                        margin: "0.4em 1em",
                      }}>
                      - Some experts do not recommend urine studies in the first
                      6 days of life
                    </p>
                  )}
                  {x.name === "Blood" && (
                    <p
                      style={{
                        color: "steelblue",
                        fontStyle: "italic",
                        opacity: "0.5",
                        fontSize: "0.6em",
                        margin: "0.4em 1em",
                      }}>
                      - CRP not routinely required but may be helpful in
                      determining length of therapy if followed serially
                    </p>
                  )}
                </List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
      )}
    </div>
  );
};

const styles = {
  btnStyle: {
    margin: "0em 0em",
    lineHeight: "1.4em",
    color: "white",
    textAlign: "left",
  },
};

export default LT7View;
