import acronyms from "../../utils/acronyms";
const a7to21_Inputs_SLo_Bro = (fullStateObj, dispatcher) => {
  const {
    core: {
      coreBase: {
        age,
        feverInflam,
        congenRisk,
        focalDx,
        ill,
        penias,
        hypotherm,
        hsvRisk,
        view,
      },
    },
    derFever: {
      derFeverBase: {
        fluidWorkup: { Urine: uWU, Blood: bWU, CSF: cWU },
        fluidInflamm: { Urine: uI, Blood: bI, CSF: cI, loInflam },
        mngRecs: { Therapy, Dispo, Course },
      },
    },
  } = fullStateObj;

  const {
    Y,
    YLP,
    MLP,
    NNLP,
    PLP,
    DEP,
    illa,
    bro,
    mayNoAbx,
    abx,
    mayAbx,
    nnAbx,
    min24,
    adm,
    dLP,
    trau,
    unsuc,
  } = acronyms;

  const bro_dispatchinator = (c) => {
    // console.log("bro_dipatchinator fired");
    return dispatcher("fluidWorkup", {
      Urine: Y,
      Blood: Y,
      CSF: c,
    });
  };

  const bro_logicizer = () => {
    uI || bI || feverInflam
      ? bro_dispatchinator(MLP)
      : uI === false && bI === false && cI === null
      ? bro_dispatchinator(NNLP)
      : bro_dispatchinator(DEP);
  };
  bro_logicizer();
};

export default a7to21_Inputs_SLo_Bro;
