import React from "react";
import InputsBtn from "../elements/InputsBtn";
import { useSelector } from "react-redux";
import { eqOne } from "../../fxns/operatorFxns";

const FeverInflamView = () => {
  const { age } = useSelector((state) => state.core.coreBase);
  const tempOpt = [
    { g385: "No, <38.5 C", color: "green", val: false },
    { g385: "Yes >38.5 C", color: "red", val: true },
  ];

  /* const ageCheckr = () => {
    return eqOne(age, ["7-21 days", "22-28 days"]) ? "Congen" : "Imm";
  }; */

  return (
    <div>
      {tempOpt.map((x, i) => (
        <InputsBtn
          btnProps={{
            field: "feverInflam",
            val: x.val,
            content: x.g385,
            nextView: "RecsStart",
            color: x.color,
            // icon: null,
            histoPush: "/recsLand",
          }}
        />
      ))}
    </div>
  );
};

export default FeverInflamView;
