import React from "react";
import { Link } from "react-router-dom";
import { Container, Dropdown, Image, Menu } from "semantic-ui-react";
import logo from "../../assets/AlgoLogo_Ocho.png";
import { useDispatch } from "react-redux";
import { resetCoreState } from "../../1actions/core_actions.js";
import history from "../../history";

const MenuCompo = () => {
  const dispatch = useDispatch();
  const returnRoot = () => {
    dispatch(resetCoreState());
    history.push({
      pathname: "/",
      // state: { v },
    });
  };
  return (
    <Menu fixed="top">
      <Container>
        {/* <Menu.Item as={Link} to="/" header> */}
        <Menu.Item onClick={() => returnRoot()} header>
          <Image size="mini" src={logo} style={{ marginRight: "0em" }} />
        </Menu.Item>
        {/* <Menu.Item as={Link} to="/" header style={styles.titleStyle}> */}
        <Menu.Header
          onClick={() => returnRoot()}
          // header
          style={styles.titleStyle}>
          Infant Fever Algo
        </Menu.Header>
        {/* <Menu.Item>
          <Dropdown item simple text="Menu" style={{ paddingLeft: "0" }}>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/">
                HOME
              </Dropdown.Item>
              <Dropdown.Item
                icon="repeat"
                text="RESET"
                onClick={() => dispatch(resetCoreState())}
              />
              <Dropdown.Item as={Link} to="/second">
                SecondPg
              </Dropdown.Item>
              <Dropdown.Item>Antibiotics</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item> */}
      </Container>
    </Menu>
  );
};

const styles = {
  titleStyle: {
    fontSize: "1.6em",
    color: "steelblue",
    margin: "1em 0em 0em 2em",
    // paddingRight: "0em",
  },
};

export default MenuCompo;
