import acronyms from "../../utils/acronyms";
import { eqOne, allTrue } from "../../fxns/operatorFxns";

const a22to28_Fluid_SLo = (
  x,
  bool,
  fullStateObj,
  dispatcher,
  workupDispatcher,
  inflamDispatcher,
  close,
  histPush
) => {
  const {
    core: {
      coreBase: { age, feverInflam, congenRisk, focalDx, ill, hsvRisk, view },
    },
    derFever: {
      derFeverBase: { fluidWorkup, fluidInflamm },
    },
  } = fullStateObj;
  const {
    Y,
    YLP,
    MLP,
    M,
    NNLP,
    NN,
    PLP,
    DEP,
    illa,
    bro,
    mayNoAbx,
    abx,
    mayAbx,
    nnAbx,
    min24,
    adm,
    dLP,
    D,
    trau,
    unsuc,
  } = acronyms;

  const arrIncluCat1to6 =
    focalDx.some((f) =>
      [
        "Focal Bacterial infection",
        "Skin or Oral vesicles",
        "Seizures",
      ].includes(f)
    ) ||
    congenRisk.some((c) => [
      "<37 wk EGA at birth",
      "Maternal Chorio or Fever within 48 hours of birth",
      "Neonatal course complicated by surgery or infection",
      "Maternal history genital HSV lesions",
      "Immunocompromised",
      "Congenital or Chromosomal abnormality",
      "Medically fragile",
    ]);

  // Below for Urine and Blood only:
  const fluidLogic = (fluid) => {
    const otherMaker = (fluid) => {
      if (fluid === "Urine") {
        return "Blood";
      } else {
        return "Urine";
      }
    };
    const other = otherMaker(fluid);
    inflamDispatcher(fluid, bool);
    bool === true && inflamDispatcher("loInflam", false);
    if (bool === true && fluidWorkup["Blood"] === DEP) {
      workupDispatcher("Blood", M);
    }

    if (bool === true && eqOne(fluidWorkup["CSF"], [MLP, DEP, dLP, D])) {
      if (
        (focalDx.includes(bro) || focalDx.includes("COVID positive")) &&
        ill !== illa
      ) {
        workupDispatcher("CSF", MLP);
      } else {
        workupDispatcher("CSF", YLP);
        inflamDispatcher("CSF", null);
      }
    }

    if (
      bool === false &&
      fluidInflamm[other] === false &&
      fluidInflamm["CSF"] === null &&
      (feverInflam === null || feverInflam === false) &&
      ill !== illa &&
      !hsvRisk &&
      congenRisk.length === 0
    ) {
      workupDispatcher("CSF", NNLP);
    }

    if (
      fluid === "Urine" &&
      bool === false &&
      eqOne(fluidInflamm["loInflam"], [false, null]) &&
      (focalDx.includes(bro) || focalDx.includes("COVID positive")) &&
      !arrIncluCat1to6
    ) {
      workupDispatcher("Blood", NN);
    }

    close(x.name);
  };

  const csfLogic = () => {
    bool === false && inflamDispatcher("CSF", false);
    if (bool === false || bool === D) {
      if (!fluidInflamm["Blood"] && !fluidInflamm["Urine"]) {
        inflamDispatcher("loInflam", true);
        dispatcher("view", "MgmtStart");
        histPush();
      }
    }

    bool === true && inflamDispatcher("CSF", true);
    bool === true && inflamDispatcher("loInflam", false);
    bool === true && workupDispatcher("Blood", Y);
    (bool === trau || bool === unsuc || bool === D) &&
      inflamDispatcher("CSF", bool);
    if (
      fluidInflamm["Urine"] !== null &&
      fluidInflamm["Blood"] !== null &&
      fluidInflamm["CSF"] !== null &&
      fluidInflamm["CSF"] !== PLP
    ) {
      // inflamDispatcher("loInflam", true);
      dispatcher("view", "MgmtStart");
      histPush();
    }
    close(x.name);
  };

  const peniaLogic = () => {
    dispatcher("penias", bool);
    bool === true && dispatcher("hsvRisk", true);
    bool === true && workupDispatcher("Blood", Y);
    bool === true && workupDispatcher("CSF", YLP);
  };

  const logicSwitcher = () => {
    return (
      (x.name === "penias" && peniaLogic()) ||
      (x.name === "hsvRisk" && dispatcher("hsvRisk", true)) ||
      (x.name === "Urine" && fluidLogic("Urine")) ||
      (x.name === "Blood" && fluidLogic("Blood")) ||
      (x.name === "CSF" && csfLogic())
    );
  };
  logicSwitcher();
};

export default a22to28_Fluid_SLo;
