const acronyms = {
  Y: "YES",
  YLP: "YES perform LP",
  MLP: "MAY perform LP",
  NNLP: "NEED NOT perform LP",
  NN: "Need not",
  PLP: "planningLP",
  DEP: "Dependent upon results of other fluids",
  illa: "Ill-appearing",
  bro: "Bronchiolitis",
  cov: "COVID positive",
  vesicles: "Skin or Oral vesicles",
  matLesions: "Maternal history genital HSV lesions",
  M: "May",
  mayNoAbx: "May withold antibiotics",
  abx: "Empiric Parenteral Antibiotics",
  abxPleo:
    "Empiric Antibiotics. Also, given CSF Pleocytosis, Assess for possible HSV and consider Acyclovir",
  abxAcyclo:
    "Empiric Parenteral Antibiotics. Also, given HSV risks, send HSV studies and Initiate Acyclovir",
  abxHsvRiskCongen:
    "Empiric Parenteral Antibiotics. Also, given HSV risks, send HSV studies and Initiate Acyclovir",
  hsvStudies:
    "HSV studies: CSF for HSV PCR, HSV PCR of serum and surface swabs: conjunctiva, NP, skin, vesicles (if present), and rectal.",
  mayAbx: "MAY start Antibiotics",
  nnAbx: "NEED NOT begin Antibiotics",
  min24: "Minimum 24 hrs monitoring of cultures",
  min24Acyclo:
    "Minimum 24 hrs monitoring of bacterial cultures and until all sent HSV studies return negative",
  posGmStain:
    "Highly suggestive for bacterial meningitis - full course of abx warranted unless convincing negative CSF Cx - consult Peds ID",
  adm: "Admit regardless",
  dLP: "deferredLP",
  C: "Consider",
  D: "deferred",
  trau: "traumatic",
  unsuc: "unsuccessful",
  hospOrHome: "May observe in Hospital or at Home",
  abxParOrOral: "Administer parenteral or oral antimicrobial",
  oralAbx: "Administer ORAL antibiotics (for presumed UTI)",
  obsHome: " May observe at home",
  obsHomeUnlessResp:
    "Unless concern for Resp decompensation, Observe closely at HOME",
  fu12to24: "Follow-up in 12-24 hours",
  fu24to36: "Follow-up in 24-36 hours",
  discWiPeds: "Discuss with Peds",
};

// add below to each file:
const {
  Y,
  YLP,
  MLP,
  NNLP,
  NN,
  PLP,
  DEP,
  illa,
  bro,
  cov,
  vesicles,
  matLesions,
  M,
  mayNoAbx,
  abx,
  abxPleo,
  abxHsvRiskCongen,
  abxAcyclo,
  hsvStudies,
  mayAbx,
  nnAbx,
  min24,
  min24Acyclo,
  posGmStain,
  adm,
  dLP,
  C,
  D,
  trau,
  unsuc,
  hospOrHome,
  abxParOrOral,
  oralAbx,
  obsHome,
  obsHomeUnlessResp,
  fu12to24,
  fu24to36,
  discWiPeds,
} = acronyms;

export default acronyms;
